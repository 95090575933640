<template>
  <div>
    <div class='row m-2'>
      <div class='col-9'>
        <p>{{ $t('notfSettings-chngPasMail') }}</p>
      </div>
      <div class='col-3'>
        <div class='checkbox'>
          <input
            type='checkbox'
            class='checkbox__input'
            id='switch1'
            v-model='notifications.email_auth_recover'
            @change="setNotification('email_auth_recover')"
            :checked='notifications.email_auth_recover'
          />
          <label class='checkbox__label' for='switch1'></label>
        </div>
      </div>
    </div>
    <!-- <div class='row m-2'>
      <div class='col-9'>
        <p>{{ $t('notfSettings-chngPasSms') }}</p>
      </div>
      <div class='col-3'>
        <div class='checkbox'>
          <input
            type='checkbox'
            class='checkbox__input'
            id='switch2'
            v-model='notifications.sms_auth_recover'
            @change="setNotification('sms_auth_recover')"
            :checked='notifications.sms_auth_recover'
          />
          <label class='checkbox__label' for='switch2'></label>
        </div>
      </div>
    </div> -->
    <div class='row m-2'>
      <div class='col-9'>
        <p>{{ $t('notfSettings-newDeviceMail') }}</p>
      </div>
      <div class='col-3'>
        <div class='checkbox'>
          <input
            type='checkbox'
            class='checkbox__input'
            id='switch3'
            v-model='notifications.email_auth_new_device'
            @change="setNotification('email_auth_new_device')"
            :checked='notifications.email_auth_new_device'
          />
          <label class='checkbox__label' for='switch3'></label>
        </div>
      </div>
    </div>
    <!-- <div class='row m-2'>
      <div class='col-9'>
        <p>{{ $t('notfSettings-newDeviceSMS') }}</p>
      </div>
      <div class='col-3'>
        <div class='checkbox'>
          <input
            type='checkbox'
            class='checkbox__input'
            id='switch4'
            v-model='notifications.sms_auth_new_device'
            @change="setNotification('sms_auth_new_device')"
            :checked='notifications.sms_auth_new_device'
          />
          <label class='checkbox__label' for='switch4'></label>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Notification',
  computed: {
    ...mapState({
      notifications: state => state.account.notifications
    })
  },
  methods: {
    ...mapActions('account', ['POST_NOTIFICATION', 'GET_NOTIFICATIONS']),
    setNotification(params) {
      this.POST_NOTIFICATION(params);
    }
  },
  created() {
    this.GET_NOTIFICATIONS();
  }
};
</script>

<style scoped></style>
