<!-- ButtonComponent.vue -->
<template>
    <button @click="loadDynamicComponent">Start KYC</button>
  </template>
  
  <script>
  export default {
    methods: {
      loadDynamicComponent() {
        this.$emit('load-component'); // Emit an event to signal that the component should be loaded
      },
    },
  };
  </script>
  