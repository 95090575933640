<template>
  <div id="wallet">
    <div class="wallet-table-th d-none">
      <div class="row">
        <div class="col-md-7 col-12 py-1">
          <p class="mt-0 text-capitalize">{{ $t('wallet-label-cryptotype') }}</p>
        </div>
        <div class="col-md-2 col-12 py-1 text-center">
          <p class="mt-0 text-capitalize">{{ $t('wallet-qr') }}</p>
        </div>
        <div class="col-md-3 col-12 py-1 text-center">
          <p class="mt-0 text-capitalize">{{ $t('wallet-availablelimit') }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse mb-1">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="hide-low-balances" v-model="hideLowBalances" />
        <span @click="onClickHideLowBalances" class="font-weight-bold hide_low_balances_lable">{{ $t('hide_low_balances') }}</span>
      </div>
    </div>
    <div v-if="wallet.length === 0">
      <content-loader :width="1000" :height="83" :speed="2" primaryColor="#ffffff" secondaryColor="#eee" class="loading-bg" v-for="(item, index) in 7" :key="'loading-' + index">
        <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
      </content-loader>
    </div>
    <template v-else>
      <section class="card pull-up" v-for="(item, index) in walletData" :key="'balance-data-' + index">
        <div class="card-content">
          <div class="card-body">
            <div class="col-12">
              <div class="row justify-content-md-center align-items-md-center">
                <div class="col-md-7 col-12">
                  <div class="media align-items-center">
                    <img :src="item.currency_icon" class="cc mr-2 font-large-2 warning d-none d-md-block" height="30" alt="" />
                    <div class="media-body">
                      <div class="mt-0 text-capitalize currency-name">{{ item.currency_code === 'USDT' ? tether : `${getCurrencyNameTranslated(item)} ${item.currency_code}` }}</div>
                      <template v-if="item.wallets && item.wallets.length > 0">
                        <div class="text-muted font-small-3 wallet-address d-flex flex-column" v-for="(wallet, walletIdx) in item.wallets" :key="`wallet-${walletIdx}`">
                          <span v-if="wallet.address" style="margin-bottom: 5px">
                            <strong>{{ wallet.asset_name }}</strong>
                            : {{ wallet.address }} <strong v-if="showUserBalance && item.currency_code === 'USDT'">({{ $t('deposit-availableLimit') }}: {{ wallet.userNetworkBalance }})</strong>
                          </span>
                          <span v-if="['XRP', 'XLM'].includes(item.currency_code)">
                            <strong>{{ item.currency_code === 'XRP' ? $t('deposit-walletTag') : $t('deposit-memo') }}</strong> {{ wallet.tag }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-12 text-center d-none d-md-block">
                  <template v-if="item.wallets && item.wallets.length === 1">
                    <div v-if="item.wallets[0]?.address" class="qrcode" @mouseover="handleMouseOverOnQr(index)" @mouseleave="handleMouseLeaveQr">
                      <!-- <div :style="{ backgroundImage: `url(${item.wallet.qr_image_code})` }" class="img-zoom"></div> -->
                      <qrcode-vue :value="item.wallets[0].address" :size="isHoveredOnQr(index) ? 200 : 50" level="H" class="img-zoom" />
                    </div>
                  </template>
                </div>
                <div class="col-md-3 col-12 text-md-right">
                  <div class="available-name">{{ item.available_formatted }}</div>
                  <p class="text-muted mb-0 font-small-3">{{ item.default_currency_total_formatted }}</p>
                </div>
                <div class="col-md-6 col-12 ml-auto" v-if="item.currency_code === 'MIR'">
                  <div class="d-flex flex-row flex-wrap justify-content-end">
                    <p class="text-muted mb-0 font-small-3" style="margin-right: 5px">{{ $t('promotion') }}:</p>
                    <p class="text-muted mb-0 font-small-3 font-weight-bold">{{ getPromotionAvailableBalance(item.promotionAvailableBalance) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'WalletList',
  data() {
    return {
      hideLowBalances: false,
      hoveredQrIndex: null,
      tether: 'Tether'
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.customer.user,
      wallet: (state) => state.wallet.wallet,
      isLoading: (state) => state.wallet.isLoading,
      series: (state) => state.wallet.series,
      userBalances: (state) => state.wallet.userBalances
    }),
    targetValue: function () {
      return this.user.id + 1000000000;
    },
    walletData() {
      if (this.hideLowBalances) {
        return this.wallet.filter((balance) => Number(balance?.available) > 0);
      }
      return this.wallet;
    },
    showUserBalance() {
      return Object.keys(this.userBalances).length > 0;
    }
  },
  components: {
    ContentLoader,
    QrcodeVue
  },
  created() {
    this.GET_BALANCE();
  },
  methods: {
    ...mapActions('wallet', ['GET_BALANCE']),
    getCurrencyNameTranslated(currency) {
      return this.$te(`service.${currency.currency_name}`) ? this.$t(`service.${currency.currency_name}`) : currency.currency_name;
    },
    onClickHideLowBalances() {
      this.hideLowBalances = !this.hideLowBalances;
    },
    getPromotionAvailableBalance(promotion) {
      if (promotion) {
        return `${parseFloat(promotion).toFixed(8)}`;
      }
      return '0.00000000';
    },
    handleMouseOverOnQr(idx) {
      this.hoveredQrIndex = idx;
    },
    handleMouseLeaveQr() {
      this.hoveredQrIndex = null;
    },
    isHoveredOnQr(idx) {
      return this.hoveredQrIndex !== null && `${this.hoveredQrIndex}` === `${idx}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.currency-name {
  font-weight: bold;
  font-size: 20px;
}
.available-name {
  font-weight: bold;
  font-size: 1.1rem;
}
.qrcode {
  height: 50px;
  position: relative;
  z-index: 1;

  .img-zoom {
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 50px;
    height: 50px;
    position: absolute;
    display: inline-block;
  }

  &:hover {
    z-index: 9;

    .img-zoom {
      width: 200px;
      height: 200px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
    }
  }
}
.hide_low_balances_lable:hover {
  cursor: pointer;
  color: #000;
}
</style>
