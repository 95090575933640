<template>
  <div id='wallet'>
    <div v-if='wallet.length === 0 && isLoading'>
      <content-loader :width='1000' :height='83' :speed='2' primaryColor='#ffffff' secondaryColor='#eee'
        class='loading-bg' v-for='(item, index) in 7' :key="'loading-' + index">
        <rect x='0' y='0' rx='6' ry='6' width='1000' height='83' />
      </content-loader>
    </div>
    <template v-else>
      <div class='col-12 pb-3' v-for='(item, index) in wallet' :key="'wallet-' + index">
        <div class='media align-items-center'>
          <img :src='item.currency_icon' class='cc mr-2 warning d-none d-md-block' height='30'
            :alt="item.currency_code" />
          <div class='media-body'>
            <div class='mt-0 text-capitalize currency-name'>{{ getCurrencyNameTranslated(item) }} {{
              item.currency_code
            }}
            </div>
            <div class='text-muted mr-2 font-small-3 wallet-address'>
              <div class='available-name'>{{ item.available_formatted }}</div>
            </div>
            <div class="d-flex flex-row flex-wrap" v-if="item.currency_code === 'MIR'">
              <p class='text-muted mb-0 font-small-3' style="margin-right: 5px;">{{ $t('promotion') }}:</p>
              <p class='text-muted mb-0 font-small-3 font-weight-bold'>{{
                getPromotionAvailableBalance(item.promotionAvailableBalance) }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if='wallet.length === 0 && !isLoading' class="alert alert-warning d-flex flex-row align-items-center"
      role="alert">
      <i class="icon-info mr-1"></i>
      <p class='mb-0 font-small-3 text-dark font-weight-bold'>{{ $t('your-total-balance-is-zero') }}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'MyAssets',
  computed: {
    ...mapState({
      wallet: state => state.wallet?.wallet?.filter(balance => Number(balance?.available) > 0),
      isLoading: state => state.wallet.isLoading,
    }),
  },
  components: {
    ContentLoader
  },
  created() {
    this.GET_BALANCE();
  },
  methods: {
    ...mapActions('wallet', ['GET_BALANCE']),
    getCurrencyNameTranslated(currency) {
      return this.$te(`service.${currency.currency_name}`) ? this.$t(`service.${currency.currency_name}`) : currency.currency_name;
    },
    getPromotionAvailableBalance(promotion) {
      if (promotion) {
        return `${parseFloat(promotion).toFixed(8)}`;
      }
      return '0.00000000';
    }
  }
};
</script>

<style lang='scss' scoped>
.currency-name {
  font-weight: bold;
  font-size: 20px;
}

.available-name {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
