<template>
  <div class="d-flex">
    <div v-for="(err, idx) in errors" :key="`error-${idx}`" class="text-danger font-small-2">
      <i class="la la-asterisk font-small-2"></i>
      <span>{{ err }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FileInputErrorsCard',
  props: ['errors'],
  methods: {}
};
</script>
<style scoped></style>
