<template>
    <div>
        <div id="onfido-div" style="display: block;">
            <div id="onfido-mount"></div>
        </div>
        <div id="in-review" class="alert alert-warning text-center " style="display: none;"
            v-html="$t('kyc-Verification-in-review')" role="alert">
        </div>
        <div id="complete" class="alert alert-success text-center fs-1" style="display: none;"
            v-html="$t('kyc-Verification-complete')" role="alert">
        </div>
    </div>
</template>
<script >


import { init as OnfidoInit } from 'onfido-sdk-ui';
import Vue from 'vue';
import Services from '@/config/_axios';
import API from '@/api';


//Vue.prototype.$http = axios;
import { mapState } from 'vuex';
import { mapActions } from 'vuex';


// import API from '@/api';
export default {
    computed: {
        ...mapState({
            user: (state) => state.customer.user,
            kycApplicantId:(state) => state.customer.kycApplicantId,
            kycStart:(state) => state.customer.kycStart,
        })
    },
    data() {
        return {
            responseData: [],
        }
    },
    methods: {
        ...mapActions('customer', ['START_KYC','KYC_UPDATESTATUS']),
        async kycStatusUpdate(option) {
      this.KYC_UPDATESTATUS(option);
    },
    openKycSdk(){
       var user=this.user
       var kyc=this.kycStart
        var update=async(option)=>{
            this.kycStatusUpdate(option)
        }
        setTimeout(async function(){
            if(this.kycStart==undefined)
            {
                console.log("There")
                let getApplicantId = `${API.kycVerify}${user.id}`;
                await Services.get(getApplicantId).then(res=>{
                    console.log(res.data.data.sdk_token,"Resource......")
                    console.log(res.data.data.workflow_run_id,"Resource......")
                    console.log("Rajat 24")

                    OnfidoInit({
                        token: res.data.data.sdk_token,
                        containerId: 'onfido-mount',
                        onComplete: async () => {
                            console.log("Rajat 23")
                        await update({ "status": 3 })
                        },
                        onError: async () => {
                            console.log("Rajat 21")
                            const dataUpdate = { "status": 1 };
                            await update(dataUpdate)
                            Vue.$toast.error("Please refresh page and start KYC again");
                            //window.location="/"

                        },
                        onUserExit: async () => {
                            console.log("Rajat 22")
                            const dataUpdate = { "status": 1 };
                            await update(dataUpdate)
                        },
                        workflowRunId: res.data.data.workflow_run_id,
                        customUI: {
                            "colorBackgroundButtonPrimary": "#642583", "colorBackgroundButtonPrimaryHover": "#642583"
                        },
                    })
                    console.log("Rajat 25")

                })
            }
            else{
                console.log("here")
                console.log("Rajat 26")

                OnfidoInit({
                    token: kyc.sdk_token,
                    containerId: 'onfido-mount',
                    onComplete: async () => {
                    await this.kycStatusUpdate({ "status": 3 });
                    },
                    onError: async () => {
                        console.log("Rajat 27")

                        const dataUpdate = { "status": 1 };
                        await this.kycStatusUpdate(dataUpdate);
                        Vue.$toast.error("Please refresh page and start KYC again");
                        window.location="/"

                    },
                    onUserExit: async () => {
                        console.log("Rajat 28")

                        const dataUpdate = { "status": 1 };
                        await this.kycStatusUpdate(dataUpdate);
                    },
                    workflowRunId: kyc.workflow_run_id,
                    customUI: {
                        "colorBackgroundButtonPrimary": "#642583", "colorBackgroundButtonPrimaryHover": "#642583"
                    },
                })
                console.log("Rajat 29")

            }
           
        }, 3000);
        }
    },
    
  created() {
    console.log("KYC START")
  },
async mounted() {
    console.log(this.kycApplicantId.kycStatus,"Rajat....")
        var onfido_div = document.getElementById("onfido-div");
        var in_review = document.getElementById("in-review");
        var complete = document.getElementById("complete");
        
    if(parseInt(this.kycApplicantId.kycStatus) === 1){
        this.openKycSdk();
    }
    else if (parseInt(this.kycApplicantId.kycStatus) === 3){
        in_review.style.display = "block"
                   onfido_div.style.display = "none"
    }
    else if (parseInt(this.kycApplicantId.kycStatus) === 5){
        complete.style.display = "block"
        onfido_div.style.display = "none"
    }
    else if(parseInt(this.kycApplicantId.kycStatus) === null || this.kycApplicantId.kycStatus== undefined || parseInt(this.kycApplicantId.kycStatus)== 404)
    {
        console.log("Here YEs")
        this.openKycSdk();
    }

},
   
};
</script>
<style lang="scss">
div#in-review {
    font-size: 20px !important;
    color: black;
}

div#complete {
    font-size: 20px !important;
    color: black;
}

#onfido-mount {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

@media (max-width: 590px) {
    #onfido-mount {
        width: 100%;
        max-width: 100%;
        height: 600px !important;
    }
    .onfido-sdk-ui-Modal-inner {
        width: 80vw !important;
        max-width: 32em !important;
        position: relative !important;
        border-radius: var(--osdk-border-radius-surface-modal) !important;
    }
}
</style>
