import API from '../api';

import Services from '@/config/_axios';

export const actions = {
  GET_HISTORY({ commit }) {
    Services.get(API.history, {
      params: {
        page: 1
      }
    })
      .then(res => {
        commit('SET_DATA', res.data);
        commit('IS_LOADING', false);
      })
      .catch(err => {
        console.error(err);
      });
  }
};
