<template>
    <div class="card tradingview-widget-container">
        <div id="tradingview_ea9b0"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TradingView',
    data() {
        return {
            symbol: null
        };
    },
    computed: {
        ...mapGetters('spottrade', ['selectedPair']),
    },
    mounted() {
        if (this.selectedPair) {
            this.symbol = `${this.selectedPair.baseCurrency}${this.selectedPair.quoteCurrency}`;
            this.startWidget();
        }
    },
    watch: {
        selectedPair: function(pair) {
            if (pair) {
                this.symbol = `${this.selectedPair.baseCurrency}${this.selectedPair.quoteCurrency}`;
                this.startWidget();
            }
        }
    },
    methods: {
        startWidget() {
            new window.TradingView.widget({
                autosize: true,
                symbol: this.symbol,
                interval: 'D',
                timezone: 'Etc/UTC',
                style: '1',
                locale: 'en',
                toolbar_bg: '#f1f3f6',
                enable_publishing: false,
                hide_top_toolbar: true,
                allow_symbol_change: true,
                container_id: 'tradingview_ea9b0',
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.tradingview-widget-container {
    display: flex;
    height: 350px;

    #tradingview_ea9b0 {
        flex: 1;
    }
}
</style>
