function accDiv(arg1, arg2) {
    var t1 = 0, t2 = 0, r1, r2;
    try { t1 = arg1.toString().split(".")[1].length } catch (e) { console.error(e) }
    try { t2 = arg2.toString().split(".")[1].length } catch (e) { console.error(e) }
    // with (Math) {
    //     r1 = Number(arg1.toString().replace(".", ""))
    //     r2 = Number(arg2.toString().replace(".", ""))
    //     return (r1 / r2) * pow(10, t2 - t1);
    // }
    r1 = Number(arg1.toString().replace(".", ""))
    r2 = Number(arg2.toString().replace(".", ""))
    return (r1 / r2) * Math.pow(10, t2 - t1);
}

Number.prototype.div = function (arg) {
    return accDiv(this, arg);
}
// String.prototype.div = function (arg) {
//     return accDiv(this, arg);
// }

function accMul(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { console.error(e) }
    try { m += s2.split(".")[1].length } catch (e) { console.error(e) }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

Number.prototype.mul = function (arg) {
    return accMul(arg, this);
}
// String.prototype.mul = function (arg) {
//     return accMul(arg, this);
// }

const getProgressValue = (val) => {
    switch (val) {
      case 10:
        return 0.2;
      case 20:
        return 0.4;
      case 30:
        return 1;
      case 40:
        return 1;
      case 60:
        return 0.6;
      case 70:
        return 0.8;
      default:
        return 0;
    }
}
const getProgressColor = (val) => {
    switch (val) {
      case 10:
        return '#ff8448';
      case 20:
        return '#4792e3';
      case 30:
        return '#40b91b';
      case 40:
        return '#dd1919';
      case 60:
        return '#f9f911';
      case 70:
        return '#a211f9';
      default:
        return '#f97f11';
    }
}

const sortArrByString = (first, second) => {
  let fa = first.toLowerCase(), fb = second.toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
}

const isNullOrUndefined = (value) => value === null || value === undefined;

const getFileSize = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default {
    accDiv,
    accMul,
    getProgressValue,
    getProgressColor,
    sortArrByString,
    isNullOrUndefined,
    getFileSize
}