export class Pair {
    base_currency_id = null;
    created_at = null;
    currency_id = null;
    id = null;
    name = null;
    symbol = null;
    quoteCurrency = null;
    baseCurrency = null;
    status = null;
    updated_at = null;
    price = null;

    constructor(pair) {
        if (pair) {
            this.base_currency_id = pair.base_currency_id;
            this.created_at = pair.created_at;
            this.currency_id = pair.currency_id;
            this.id = pair.id;
            this.name = pair.name;
            this.symbol = pair.symbol;
            this.baseCurrency = pair.baseCurrency;
            this.quoteCurrency = pair.quoteCurrency;
            this.price = pair.price;
            this.status = pair.status;
            this.updated_at = pair.updated_at;
        }
    }
}
