<template>
    <div class="table-responsive col-2 card m-0 h-100 overflow-scroll">
        <table class="table spot-trade-table">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('commons.price') }}</th>
                    <th class="text-right">{{ $t('commons.amount') }}</th>
                    <th class="text-right">{{ $t('commons.total') }}</th>
                </tr>
            </thead>
            <tbody v-if="sellOrders.length > 0">
                <tr v-for="(ticker, index) in sellOrders" :key="'pair-' + index"
                    :class="[getDirectionClass(ticker.direction)]">
                    <td class="text-center">{{ ticker.lastPrice }}</td>
                    <td class="text-right">{{ ticker.lastQuantity }}</td>
                    <td class="text-right">{{ ticker.lastAmount | currency('') }}</td>
                </tr>
            </tbody>
            <div class="my-1"></div>
            <tbody v-if="buyOrders.length > 0">
                <tr v-for="(ticker, index) in buyOrders" :key="'pair-' + index"
                    :class="[getDirectionClass(ticker.direction)]">
                    <td class="text-center">{{ ticker.lastPrice }}</td>
                    <td class="text-right">{{ ticker.lastQuantity }}</td>
                    <td class="text-right">{{ ticker.lastAmount | currency('') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/config/_currency';
import {SockIOService} from '@/config/ofinansWebSocket';

export default {
    name: 'LeftBlock',
    filters: { currency },
    computed: {
        ...mapGetters('spottrade', ['symbolTickerStreams', 'selectedPair']),
        sellOrders() {
            return this.symbolTickerStreams.filter(val => val.direction === 'SELL');
        },
        buyOrders() {
            return this.symbolTickerStreams.filter(val => val.direction === 'BUY');
        },
    },
    methods: {
        getDirectionClass(direction) {
            let _class = null;
            switch (direction) {
                case 'BUY':
                    _class = 'text-success';
                    break;
                case 'SELL':
                    _class = 'text-danger';
                    break;
            }
            return _class;
        },
        initAndSubscribeWS(pairName) {
            SockIOService.init();
            SockIOService.socket.on(`trade-plate.${pairName}`, (/* data */) => {
                this.$store.dispatch('spottrade/FETCH_TICKER_STREAMS', pairName);
            })
        }
    },
    destroyed() {
        SockIOService.disconnect();
    },
    created() {
        if (this.selectedPair.name) {
            this.initAndSubscribeWS(this.selectedPair.name);
        }
    },
    watch: {
        selectedPair: function(val) {
            if(val.name) {
                this.initAndSubscribeWS(val.name);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.spot-trade-table {

    th,
    td {
        padding: 1rem 0.25rem;
    }
}
</style>