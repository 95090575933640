<template>
  <div class="content-wrapper">
    <Breadcrumb :title="$t('instanttrade-title')" />
    <Marquee />
    <div class="card-deck">
      <div class="card pull-up-only-shadow col-xl-7 col-lg-7 col-md-12">
        <div class="card-body">
          <div class="buy-sell-widget">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link -buy" :class="quicksale.type === 10 ? 'active' : ''" @click="changeTab(10)"
                  data-toggle="tab" href="#buy">
                  {{ $t('instanttrade-buynow') }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link -sell" :class="quicksale.type === 20 ? 'active' : ''" @click="changeTab(20)"
                  data-toggle="tab" href="#sell">
                  {{ $t('instanttrade-sellnow') }}
                </a>
              </li>
            </ul>
            <div class="tab-content tab-content-default">
              <div class="tab-pane fade" :class="quicksale.type === 10 ? 'active show' : ''" id="buy" role="tabpanel">
                <BuyNow v-if="quicksale.type === 10" :minTradeTotal="minTradeTotal" />
              </div>
              <div class="tab-pane fade" :class="quicksale.type === 20 ? 'active show' : ''" id="sell">
                <SellNow v-if="quicksale.type === 20" :minTradeTotal="minTradeTotal" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-transparent col-xl-5 col-lg-5 col-md-12">
        <div class="card card-transparent">
          <div class="card-body">
            <div class="bd-content">
              <template v-if="!staticContent">
                <content-loader :width="1000" :height="63" :speed="2" primaryColor="#f4f5fa" secondaryColor="#eee"
                  class="loading-bg" v-for="index in 5" :key="'loading-' + index">
                  <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                </content-loader>
              </template>
              <div v-if="staticContent" class="mb-1">
                <h3 class="h3">{{ staticContent.title }}</h3>
                <p v-for="(item, index) in staticContent.content" :key="'content-' + index" v-html="item"></p>
                <!-- <div class="primeTrustWrapper">
                <img v-if="staticContent.content" src="../assets/images/logo/prime-trust-logo.png" alt="" />
              </div> -->
              </div>
            </div> 
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div style="height: 600px; overflow-y: auto;">
              <MyAssets />
            </div>
          </div>
        </div>  
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card pull-up-only-shadow">
          <div class="card-body">
            <div class="buy-sell-widget">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link -buy" :class="quicksale.type === 10 ? 'active' : ''" @click="changeTab(10)"
                    data-toggle="tab" href="#buy">
                    {{ $t('instanttrade-buynow') }}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link -sell" :class="quicksale.type === 20 ? 'active' : ''" @click="changeTab(20)"
                    data-toggle="tab" href="#sell">
                    {{ $t('instanttrade-sellnow') }}
                  </a>
                </li>
              </ul>
              <div class="tab-content tab-content-default">
                <div class="tab-pane fade" :class="quicksale.type === 10 ? 'active show' : ''" id="buy" role="tabpanel">
                  <BuyNow v-if="quicksale.type === 10" />
                </div>
                <div class="tab-pane fade" :class="quicksale.type === 20 ? 'active show' : ''" id="sell">
                  <SellNow v-if="quicksale.type === 20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="bd-content p-4">
          <template v-if="!staticContent">
            <content-loader :width="1000" :height="63" :speed="2" primaryColor="#f4f5fa" secondaryColor="#eee"
              class="loading-bg" v-for="index in 5" :key="'loading-' + index">
              <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
            </content-loader>
          </template>
          <div v-if="staticContent" class="mb-1">
            <h3 class="h3">{{ staticContent.title }}</h3>
            <p v-for="(item, index) in staticContent.content" :key="'content-' + index" v-html="item"></p>
          </div>
          <div style="height: 500px; overflow-y: auto;">
            <MyAssets />
          </div>
        </div>
      </div>
    </div> -->
    <History />
    <!-- <Modal v-if="buyNow.showSummaryModal" ref="modalBuyNowName" :title="$t('instanttrade-tradeSuccessSumBuy')"
      :datas="buyNow.summary" />
    <Modal v-if="sellNow.showSummaryModal" ref="modalSellNowName" :title="$t('instanttrade-tradeSuccessSumSell')"
      :datas="sellNow.summary" /> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BuyNow from '@/components/instant-trade/BuyNow.vue';
import SellNow from '@/components/instant-trade/SellNow.vue';
//import Modal from '@/components/instant-trade/Modal';
import History from '@/components/instant-trade/History';
import Breadcrumb from '@/components/layout/Breadcrumb';
import Marquee from '@/components/markets/Marquee.vue';
import { ContentLoader } from 'vue-content-loader';
import MyAssets from '../components/instant-trade/MyAssets.vue';

export default {
  name: 'InstantTrade',
  components: {
    BuyNow,
    SellNow,
    History,
    Breadcrumb,
    ContentLoader,
    Marquee,
    MyAssets
  },
  computed: {
    ...mapState({
      buyNow: (state) => state.quicksale.buyNow,
      sellNow: (state) => state.quicksale.sellNow,
      quicksale: (state) => state.quicksale.quicksale,
      staticContent: (state) => {
        if (state.quicksale.quicksale.type === 10) {
          return state.quicksale.buyNow.staticContent;
        } else if (state.quicksale.quicksale.type === 20) {
          return state.quicksale.sellNow.staticContent;
        }
      }
    }),
    minTradeTotal() {
      return 5;
    }
  },
  methods: {
    ...mapActions('quicksale', [
      'GET_TRY_BALANCE',
      'GET_BUY_NOW_PRICES',
      'GET_CURRENCY_BALANCE',
      'GET_SELL_NOW_PRICES'
    ]),
    changeTab(type) {
      this.quicksale.type = type;
      this.quicksale.amount = null;
      this.quicksale.total = null;
    }
  }
};
</script>
