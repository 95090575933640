<template>
    <div class="card pull-up-only-shadow mb-0 mt-0">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <section>
                    <span class="mr-1">{{ $t('wallet-availablelimit')}}</span>
                    <span class="text-bold-600 text-dark">{{ availableFormatted }}</span>
                </section>
                <section>
                    <span class="mr-1">{{ $t('commons.total')}}</span>
                    <span class="text-bold-600 text-dark">{{ totalFormatted}}</span>
                </section>
            </div>
            <div class="d-flex mt-1">
                <div>
                    <button
                        type="button"
                        class="btn btn-link bg-transparent text-dark"
                        :class="{'font-weight-bold': selectedType === typeLimit}"
                        @click.prevent="setSelectedType(typeLimit)"
                    >
                        {{ $t("spotTrade.limit") }}
                    </button>
                    <div
                        style="height: 4px"
                        class="rounded"
                        :class="[selectedType === typeLimit ? 'bgPrimary' : 'bgMuted']"
                    />
                </div>
                <div class="ml-1">
                    <button
                        type="button"
                        class="btn btn-link bg-transparent text-dark"
                        :class="{'font-weight-bold': selectedType === typeMarket}"
                        @click.prevent="setSelectedType(typeMarket)"
                    >
                        {{ $t("spotTrade.market") }}
                    </button>
                    <div
                        class="rounded"
                        style="height: 4px"
                        :class="[selectedType === typeMarket ? 'bgPrimary' : 'bgMuted']"
                    />
                </div>
            </div>
            <div class="d-flex mt-1 flex-row justify-content-around border border-secondary rounded">
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedOrderDirection === directionBuy ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedOrderDirection(directionBuy)"
                >
                    {{ $t('spotTrade.buy') }}
                </button>
                <div class="bgMuted rounded" style="width: 2px; height: inherit; margin: 3px 0 3px 0" />
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedOrderDirection === directionSell ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedOrderDirection(directionSell)"
                >
                    {{ $t('spotTrade.sell') }}
                </button>
            </div>
            <div class="d-flex mt-2">
                <TransactionForm
                    :direction="selectedOrderDirection"
                    :type="selectedType"
                    ref="transactionForm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OrderDirection } from '@/config/order.enum';
import { OrderType } from '@/config/order.enum';
import TransactionForm from './TransactionForm';

export default {
    name: "TransactionsMain",
    computed: {
        ...mapGetters('spottrade', ['pairBalance', 'selectedType', 'selectedOrderDirection']),
        availableFormatted() {
            return this.pairBalance?.available_formatted || '0';
        },
        totalFormatted() {
            return this.pairBalance?.available_formatted || '0';
        },
        typeLimit() {
            return OrderType.Limit;
        },
        typeMarket() {
            return OrderType.Market
        },
        directionBuy() {
            return OrderDirection.Buy;
        },
        directionSell() {
            return OrderDirection.Sell;
        }
    },
    methods: {
        ...mapActions('spottrade', ['SUBMIT_ORDER', 'SET_PAIR_BALANCE_BY_DIRECTION']),
        setSelectedType(type) {
            // this.selectedType = type;
            this.$store.commit('spottrade/SET_SELECTED_TYPE', type);
            this.$refs.transactionForm.resetForm();
        },
        setSelectedOrderDirection(orderDirection) {
            
            // this.selectedOrderDirection = orderDirection;
            this.$store.commit('spottrade/SET_SELECTED_ORDER_DIRECTION', orderDirection);
            this.$refs.transactionForm.resetForm();            
            // ex: TRX/TRY get balance
            /* if -> orderDirection is this.directionBuy
                // get quoteCurrency(TRY) balance
            if -> orderDirection is this.directionSell
                // get baseCurrency(TRX) balance
            */
            this.SET_PAIR_BALANCE_BY_DIRECTION();
        }
    },
    components: { TransactionForm },
};
</script>

<style lang="scss" scoped>
.transaction-forms {
    margin-bottom: 9px;
}
.bgPrimary {
    background-color: #77519c;
}
.bgMuted {
    background-color: #e3cdf8;
}
.textMuted {
    color: #87719e;
    opacity: .8;
}
.selectedText {
    color: #77519c;
    font-weight: bolder;
}
</style>