export const languages = {
  en: {
    title: 'English',
    lang_code: 'en',
    flag_code: 'gb'
  },
  nl: {
    title: 'Dutch',
    lang_code: 'nl',
    flag_code: 'nl'
  },
};
