<template>
    <div class="px-2 mt-2">
        <div class="d-flex justify-content-between align-items-center">
            <h6 class="my-md" :class="{ 'mt-2': !selectable }">
                {{ $t(title) }}
            </h6>
            <section>
                <button v-if="selectable" type="button" class="btn btn-gradient-primary btn-sm mb-2"
                    :class="{ disabled: selectedOrders.length < 1 }" :disabled="selectedOrders.length < 1"
                    @click="onCancelSelected">
                    {{ $t('spotTrade.cancel-selected') }}
                </button>
            </section>
        </div>
        <div class="table-responsive card m-0 p-0">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center vertical-initial"></th>
                        <!-- <th class="text-center vertical-top"></th> -->
                        <th class="text-center">{{ $t('commons.transaction_time') }}</th>
                        <th class="text-center">{{ $t('spotTrade.pair') }}</th>
                        <th class="text-right">{{ $t('spotTrade.order') }}</th>
                        <th class="text-center">{{ $t('exchange.transaction_type') }}</th>
                        <th class="text-center">{{ $t('exchange.direction') }}</th>
                        <th class="text-right">{{ $t('commons.price') }}</th>
                        <th class="text-right">{{ $t('commons.amount') }}</th>
                        <th class="text-right">{{ $t('commons.total') }}</th>
                        <th class="text-right">{{ $t('commons.commission') }}</th>
                        <th class="text-right">{{ $t('commons.remaining') }}</th>
                        <th class="text-center">{{ $t('commons.status') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(order, index) in orders">
                        <tr :key="'pair-' + index" class="main-order-row">
                            <!-- <td class="text-center vertical-initial">
                                <button v-if="isItemCollapsible(order)" type="button" @click="collapseOrder(order.order_id)"
                                    class="btn btn-icon btn-sm bg-primary align-items-center justify-content-center"
                                    style="border-radius: 50%;">
                                    <i class="text-white"
                                        :class="isCollapsed(order.order_id) ? 'la la-minus' : 'la la-plus'"></i>
                                </button>
                            </td> -->
                            <td class="text-center vertical-top">
                                <input v-if="selectable && order.status !== 2" class="form-check-input" type="checkbox"
                                    :value="order" v-model="selectedOrders" :id="`order-check-${index}`">
                            </td>
                            <td class="text-center">{{ (order.time * 1000) | dateFormat }}</td>
                            <td class="text-center">{{ order.symbol }}</td>
                            <td class="text-center">{{ $t('spotTrade.order_given') }}</td>
                            <td class="text-center">{{ $t(getTransactionType(order.type)) }}</td>
                            <td class="text-center">{{ $t(getDirection(order.direction)) }}</td>
                            <!-- <td class="text-right">{{ order.price | currency('') }}</td> -->
                            <td class="text-right">{{ getTablePriceColumn(order) }}</td>
                            <!-- <td class="text-right">{{ order.amount | currency('', getCurrencyFilterDecimal(order.direction)) }}</td> -->
                            <td class="text-right">{{ getTableAmountColumn(order) }}</td>
                            <!-- <td class="text-right">{{ order.price * order.amount | currency('') }}</td> -->
                            <td class="text-right">{{ getTableTotalColumn(order) }}</td>
                            <td class="text-right">--</td>
                            <!-- <td class="text-right">{{ getRemaining(order) | currency('') }}</td> -->
                            <td v-if="order.status==1">0</td>
                            <td v-else-if="$t(getTransactionType(order.type)) != 'Market'" class="text-right">{{
                                getRemaining(order) }}</td>
                            <td v-else class="text-right">--</td>


                            <td class="text-center" :class="[getStatusClass(order.status)]">{{ $t(getStatus(order.status))
                            }}
                            </td>
                        </tr>
                        <template v-if="isItemCollapsible(order) && isCollapsed(order.order_id)">
                            <tr v-for="(detailItem, detailItemIndex) in order.detail"
                                :key="`detailitem-${detailItemIndex}`">
                                <td colspan="4"></td>
                                <td class="text-center text-primary">{{ $t('spotTrade.order_completed') }}</td>
                                <td colspan="2"></td>
                                <td class="text-right text-primary">{{ detailItem.price }}</td>
                                <td class="text-right text-primary">{{ detailItem.amount }}</td>
                                <td class="text-right text-primary">{{ calculateTotal(detailItem.price, detailItem.amount)
                                }}</td>
                                <td class="text-right text-primary">{{ detailItem.fee }} {{
                                    getCurrencyCodeByDirection(order) }}</td>
                                <td colspan="1"></td>
                                <td class="text-center text-success">{{ $t('service.pending.completed') }}</td>
                            </tr>
                        </template>
                    </template>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import dateFormat from '@/config/_date-format';
import currency from '@/config/_currency';
import { OrderDirection, OrderType } from '../../config/order.enum';

export default {
    name: 'OrdersTable',
    props: {
        orders: Array,
        title: String,
        selectable: Boolean
    },
    filters: {
        dateFormat, currency
    },
    data() {
        return {
            selectedOrders: [],
            collapsedRows: [],
        };
    },
    computed: {
        limitOrder() {
            return OrderType.Limit;
        },
        marketOrder() {
            return OrderType.Market;
        },
        buyDirection() {
            return OrderDirection.Buy;
        },
        sellDirection() {
            return OrderDirection.Sell;
        }
    },
    methods: {
        ...mapActions('spottrade', ['CANCEL_ORDERS']),
    ...mapActions('customer', ['UPDATE_EXCHANGE_CURRENCY']),

        getTransactionType(type) {
            const transactionType = type === 0 ? 'limit' : 'market';
            return `spotTrade.${transactionType}`;
        },
        getDirection(direction) {
            const transactionDirection = direction === 0 ? 'buy' : 'sell';
            return `service.${transactionDirection}`;
        },
        getStatus(status) {
            let transactionStatus = null;
            switch (status) {
                case 0:
                    transactionStatus = 'pending';
                    break;
                case 1:
                    transactionStatus = 'completed';
                    break;
                case 2:
                    transactionStatus = 'canceled';
                    break;
            }

            return `service.pending.${transactionStatus}`;
        },
        getStatusClass(status) {
            let transactionClass = null;
            switch (status) {
                case 0:
                    transactionClass = 'text-warning';
                    break;
                case 1:
                    transactionClass = 'text-success';
                    break;
                case 2:
                    transactionClass = 'text-danger';
                    break;
            }
            return transactionClass;
        },
        async onCancelSelected() {
            await this.CANCEL_ORDERS(this.selectedOrders);
            this.selectedOrders = [];
        },
        getRemaining(order) {
            if (order.type === this.marketOrder && order.direction === this.buyDirection) {
                return '--';
            }
            if (order && order.amount && order.traded_amount) {
                const remaining = parseFloat(order.amount) - parseFloat(order.traded_amount);
                return remaining < 0 ? '0' : `${remaining}`;
            }
            return '0';
        },
        collapseOrder(orderID) {
            const index = this.collapsedRows.indexOf(orderID);
            if (index > -1) {
                this.collapsedRows.splice(index, 1);
                return;
            }
            this.collapsedRows.push(orderID)
        },
        isCollapsed(id) {
            return this.collapsedRows.includes(id);
        },
        isItemCollapsible(item) {
            return item?.detail && item.detail.length > 0;
        },
        getCurrencyCodeByDirection(order) {
            if (order?.direction === 0) return order.coin_symbol;
            return order.base_symbol;
        },
        getCurrencyFilterDecimal(direction) {
            let decimal = 2;
            const isBuy = direction === OrderDirection.Buy;
            if (!isBuy) {
                decimal = 8;
            }
            return decimal;
        },
        calculateTotal(price, amount) {
            if (price && amount)
                return parseFloat(`${price}`.replace(/,/g, '')) * parseFloat(`${amount}`.replace(/,/g, ''));
            return 0;
        },
        getTablePriceColumn(item) {
            if (item.type === this.marketOrder && (item.direction === this.buyDirection || item.direction === this.sellDirection)) {
                return '--';
            }
            return item.formatted_price;
        },
        getTableAmountColumn(item) {
            if (item.type === this.marketOrder && item.direction === this.buyDirection) {
                /* if(item?.detail.length > 0) {
                    return item.detail.reduce((acc, val) => parseFloat(acc) + parseFloat(val.amount), 0);
                } */
                return '--';
            }
            return item.formatted_amount;
        },
        getTableTotalColumn(item) {
            if (item.type === this.marketOrder && item.direction === this.buyDirection) {
                return item.formatted_amount;
            }
            if (item.type === this.marketOrder && item.direction === this.sellDirection) {
                return '--';
            }
            return this.calculateTotal(item.price, item.amount);
        },
    },
    created() {
    this.UPDATE_EXCHANGE_CURRENCY();
  }
};
</script>

<style lang="scss" scoped>
.vertical-initial {
    vertical-align: baseline;
}

.vertical-top {
    vertical-align: top;
}

.table-responsive {
    height: 390px;
    overflow-y: scroll;
}

.bg-primary {
    background-color: #642583 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #491068 !important;
}

.main-order-row td {
    font-weight: bold;
}
</style>