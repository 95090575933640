import i18n from './_i18n';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import utils from './utils';

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty']
  }
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: (_, values) => i18n.t(`validation.${rule}`, values)
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: i18n.t('veeValidateExtraMsgs.passwords_do_not_match')
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: i18n.t('veeValidateExtraMsgs.strong_password')
});
extend('img_required', {
  validate(imgUrl) {
    return imgUrl !== '';
  },
  message() {
    return i18n.t('veeValidateExtraMsgs.required_field');
  }
});
extend('file_size_less_than_mb', {
  params: ['size'],
  validate(files, args) {
    if(files && files[0] && files[0] instanceof File && args?.size) {
      const fileSizeInMb = Math.floor(files[0].size/(1024*1024));
      if(fileSizeInMb > Number(args.size)) {
        return false;
      }
    }
    return true;
  },
  message() {
    return i18n.t('veeValidateExtraMsgs.file_size_less_than_mb', { size: '15' });
  }
});
extend('num_greater_than', {
  params: ['entered'],
  validate(value, {entered}) {
    if (value && entered && parseFloat(value) > parseFloat(entered)) return true;
    return false;
  },
  message(fieldName, {entered}) {
    return i18n.t('veeValidateExtraMsgs.greater_than', {fieldName, entered});
  }
});
extend('insufficient_balance', {
  params: ['entered'],
  validate(balance, {entered}) {
    if (utils.isNullOrUndefined(balance) || balance === '') return false;
    if (utils.isNullOrUndefined(entered) || entered === '') return false;
    if (parseFloat(balance) <= parseFloat(entered)) return true;
    return false;
  },
  message() {
    return i18n.t('service.Yetersiz Bakiye');
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
