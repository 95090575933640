<template>
  <div>
    <div class="form-group">
      <label class="mr-sm-2">{{ $t('deposit-title') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedCurrency" @input="getCoinSelection" deselect-label="" select-label="" selected-label="" track-by="name" label="name" :custom-label="currencySelectCustomLabel" :show-labels="false" :placeholder="$t('commons.choose')" :options="depositCurrency" :allow-empty="false" open-direction="bottom">
          <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template> -->
          <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::COUNTRIES -->
    <div class="form-group" v-if="!selectedCurrency.crypto">
      <label class="mr-sm-2">{{ $t('commons.country') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedCountry" @input="onChangeCountry" deselect-label="" select-label="" selected-label="" track-by="name" label="name" :placeholder="$t('commons.choose')" :options="countries" :allow-empty="false" open-direction="bottom">
          <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
          <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::COUNTRIES -->
    <!-- BEGIN::USDT providers -->
    <div class="form-group" v-if="selectedCurrency.crypto && selectedCurrency.code === 'USDT'">
      <label class="mr-sm-2">{{ $t('network') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedNetwork" @input="onNetworkChange" deselect-label="" select-label="" selected-label="" track-by="name" :label="$t('network')" :options="networks" :allow-empty="false" open-direction="bottom">
          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} </template>
          <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::USDT providers -->
    <template v-if="!selectedCurrency.crypto">
      <ul class="nav nav-tabs d-none">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#transfer">{{ $t('deposit-section1') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#creditcard">{{ $t('deposit-section2') }}</a>
        </li>
      </ul>
      <div class="tab-content tab-content-default">
        <div class="tab-pane fade active show" id="transfer" role="tabpanel">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(askForDepositApproval)">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('deposit-enterAmount') }}</label>
                  <router-link to="/personal/bank-settings" active-class="active" class="add-bank" id="Add Bank">
                    {{ $t('personalMenu-bankSettings') }}
                  </router-link>
                </div>
                <div class="row">
                  <validation-provider rules="required" name="Miktar" v-slot="{ classes, errors }" class="col-6">
                    <currency-input id="amount" class="form-control" :class="classes" v-model="amount" :placeholder="getAmountPlaceHolder" />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }" class="col-6">
                    <multiselect v-model="selectedBank" deselect-label="" select-label="" selected-label="" track-by="bank" label="bank" :placeholder="$t('deposit-dropdownBank')" :options="bankList" :allow-empty="false" :class="classes" open-direction="bottom" @input="showPopup">
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>

                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div v-if="popupVisible" class="popup">
                  <p class="set-color">We ONLY accept fundings from a bank account under your own name. Multiple deposits of the same amounts within a few days of each other may be delayed. If you must make multiple deposits, consider sending different amounts. You MUST include the Reference Code in your deposit in order to credit your account!</p>
                  <p class="set-color">Reference Code: {{ referenceNumber }}</p>

                  <p class="set-color">This code identifies your deposit with your account. Include this code when submitting the wire transfer.</p>
                  <h2>Account Details</h2>
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>Account Number Iban</th>
                      <th>Address</th>
                      <th>Bic Swift Code</th>
                      <th>Bank Address</th>
                    </tr>
                    <tr v-for="(item, index) in accountDetailsDeposit" :key="'accountDetailsDeposit-' + index">
                      <td @click="copyText(item.name)">{{ item?.name }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
                      <td @click="copyText(item.account_number_iban)">{{ item?.account_number_iban }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
                      <!-- <td>{{ item?.account_number_iban }}</td> -->
                      <td @click="copyText(item.address)">{{ item?.address }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
                      <td @click="copyText(item.bic_swift_code)">{{ item?.bic_swift_code }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
                      <td @click="copyText(item.bank_address)">{{ item?.bank_address }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
                    </tr>
                  </table>

                  <!-- <button @click="closePopup">Close</button> -->
                </div>
                <div v-if="selectedBank" class="d-flex w-100 mt-1">
                  <div class="alert alert-warning" role="alert" v-html="$t('deposit-limitWarning')"></div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <p class="mb-0">{{ $t('deposit-availableLimit') }}</p>
                  <h6 class="mb-0">{{ userBalance }}</h6>
                </div>

                <div class="d-flex justify-content-center" v-if="isLoading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="form-group" v-if="!isLoading && isDeposit">
                  <div class="d-flex justify-content-center mt-1">
                    <img id="bankLogo" :src="selectedBank.logo" />
                  </div>
                  <div class="d-flex justify-content-center mt-1 font-weight-bold">
                    {{ transfer.bank }}
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-bankRecipient') }}</span>
                    <span class="mb-0">{{ transfer.name }}</span>
                    <input name="transferName" type="hidden" ref="transferName" v-model="transfer.name" />
                    <a class="ml-1" @click="copyText($refs.transferName)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <div v-html="transfer.address"></div>
                    <input name="transferAddress" type="hidden" ref="transferAddress" v-model="transfer.address" />
                    <a class="ml-1" @click="copyText($refs.transferAddress)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-transferDesc') }}</span>
                    <span class="mb-0">{{ transfer.code }}</span>
                    <input name="transferCode" type="hidden" ref="transferCode" v-model="transfer.code" />
                    <a class="ml-1" @click="copyText($refs.transferCode)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <button type="submit" class="btn-gradient-primary btn-block">
                    {{ $t('deposit-btn-confirm') }}
                  </button>
                </div>
                <div><br /></div>
                <!-- <div
                  class="alert alert-warning"
                  role="alert"
                  v-html="
                    $t('deposit-fee', {
                      amount: selectedBank ? selectedBank.min_pay_formatted : ''
                    })
                  "
                ></div> -->
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" id="creditcard" role="tabpanel">
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-name') }}</label>
            <div class="input-group">
              <input type="text" name="nameSurname" class="form-control" :placeholder="$t('deposit-cc-name')" />
            </div>
          </div>
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-number') }}</label>
            <div class="input-group">
              <input type="text" name="cardNo" class="form-control" :placeholder="$t('deposit-cc-number')" />
            </div>
          </div>
          <label class="mr-sm-2">{{ $t('deposit-cc-dateTime') }}</label>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-month') }}</label>
                <input type="text" name="mounth" class="form-control" :placeholder="$t('deposit-cc-month-placeholder')" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-year') }}</label>
                <input type="text" name="year" class="form-control" :placeholder="$t('deposit-cc-year-placeholder')" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-cvv') }}</label>
                <input type="text" name="cvv" class="form-control" :placeholder="$t('deposit-cc-cvv')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="alert alert-warning" role="alert" v-html="$t('min_deposit_crypto_amount_warning')"></div>
      <template v-if="getSelectedWalletAddress">
        <div class="d-flex justify-content-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-walletAddress') }}</span>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <span class="walletAddress">{{ getSelectedWalletAddress }}</span>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <button type="button" :disabled="buttonLoading" @click="copyText(getSelectedWalletAddress)" class="btn btn-blue ml-1 mb-0">
            <i class="ficon icon-layers"></i>
            {{ $t('wallet-detail-btn-copy') }}
          </button>
        </div>
        <template v-if="selectedCurrency.code === 'XRP' || selectedCurrency.code === 'XLM'">
          <div>
            <div class="d-flex justify-content-center mt-3">
              <span class="font-weight-bold" v-if="selectedCurrency.code === 'XRP'">{{ $t('deposit-walletTag') }}</span>
              <span class="font-weight-bold" v-if="selectedCurrency.code === 'XLM'">{{ $t('deposit-memo') }}</span>
            </div>
            <div class="d-flex justify-content-center mt-1">
              <span>{{ getSelectedWalletTag }}</span>
            </div>
            <div class="d-flex justify-content-center mt-1">
              <button type="button" @click="copyText(getSelectedWalletTag)" class="btn btn-blue ml-1">
                <i class="ficon icon-layers"></i>
                {{ $t('wallet-detail-btn-copy') }}
              </button>
            </div>
          </div>
        </template>
      </template>
      <div class="d-flex justify-content-center mt-1" v-else>
        <button type="button" :disabled="buttonLoading" @click="generateAddress()" class="btn btn-blue ml-1 mb-0">
          <i class="ficon icon-layers"></i>
          {{ $t('generate-address') }}
        </button>
      </div>

    </template>
    <IssuersModal v-if="issuersModal" ref="issuersModal" @onClose="issuersModal = false" :modalDialogStyle="issuersModalStyle">
      <template v-slot:header>
        <h1>{{ $t('commons.important_info') }}</h1>
      </template>
      <template v-slot:body>
        <div class="mb-2">As MiracleCash, we serve our customers through SEPA. The bank account where you need to send fiat deposits is given below :-</div>
        <ul class="list-group list-group-flush">
          <!-- <li
            class="list-group-item" v-for="(issuer, issuerKey) in issuersData"
            :key="`issuer-${issuerKey}`"
          >
            {{ issuer.name }}
          </li> -->
        </ul>
        <h2>Account Details</h2>
        <table>
          <tr>
            <th>Name</th>
            <th>Account Number Iban</th>
            <th>Address</th>
            <th>Bic Swift Code</th>
            <th>Bank Address</th>
          </tr>
          <tr v-for="(item, index) in accountDetailsDeposit" :key="'accountDetailsDeposit-' + index">
            <td @click="copyText(item.name)">{{ item?.name }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
            <td @click="copyText(item.account_number_iban)">{{ item?.account_number_iban }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
            <!-- <td>{{ item?.account_number_iban }}</td> -->
            <td @click="copyText(item.address)">{{ item?.address }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
            <td @click="copyText(item.bic_swift_code)">{{ item?.bic_swift_code }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
            <td @click="copyText(item.bank_address)">{{ item?.bank_address }}<i class="fa fa-copy cursor-pointer ml-1 copy-icon"></i></td>
          </tr>
        </table>
      </template>

      <!-- <template v-slot:footer>
        <div class="d-flex flex-column text-center">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" v-model="hideIssuersModal" />
            <span @click="$store.commit('deposit/onChangeHideIssuersModal', !hideIssuersModal)" class="font-weight-bold">{{ $t('commons.dont_show_it_again') }}</span>
          </div>
        </div>
      </template> -->
    </IssuersModal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2';
import IssuersModal from '@/components/global/Modal';

export default {
  name: 'DepositForm',
  components: {
    Multiselect,
    IssuersModal
  },
  data: () => ({
    issuersModal: false,
    windowHeight: '500px',
    popupVisible: false,
    popupMessage: '',
    referenceNumber: ''
  }),
  computed: {
    ...mapState({
      buttonLoading: (state) => state.deposit.buttonLoading,
      currency: (state) => state.global.currency,
      selectedWalletAddress: (state) => state.withdrawal.selectedWalletAddress,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user,
      isCrypto: (state) => state.deposit.isCrypto,
      wallet: (state) => state.deposit.selectedWallet,
      bankList: (state) => state.deposit.bankList,
      userBalance: (state) => state.deposit.userBalance,
      isDeposit: (state) => state.deposit.isDeposit,
      isTransfer: (state) => state.deposit.isTransfer,
      transfer: (state) => state.deposit.transfer,
      countries: (state) => state.customer.countries,
      networks: (state) => state.deposit.networks,
      issuersData: (state) => state.account.issuersData,
      accountDetailsDeposit: (state) => state.deposit.bankDetailDeposit
    }),
    getSelectedWalletAddress() {
      return this.wallet?.address || null;
    },
    getSelectedWalletTag() {
      return this.wallet?.tag || null;
    },
    selectedBank: {
      get() {
        return this.$store.state.deposit.selectedBank;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedBank', value);
        if (value?.id) {
          this.FETCH_TRANSFER({
            provider_id: value.id,
            currency: this.selectedCurrency.code
          });
        }
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.deposit.selectedCurrency;
      },
      set(value) {
        this.CANCEL_DEPOSIT();
        this.$store.commit('deposit/updateSelectedCurrency', value);
      }
    },
    amount: {
      get() {
        return this.$store.state.deposit.amount;
      },
      set(value) {
        this.$store.commit('deposit/updateAmount', value);
      }
    },
    targetValue: function () {
      return this.user.id + 1000000000;
    },
    depositCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.deposit;
      });
    },
    selectedCountry: {
      get() {
        return this.$store.state.deposit.selectedCountry;
      },
      set(value) {
        this.CANCEL_DEPOSIT();
        this.$store.commit('deposit/updateSelectedCountry', value);
      }
    },
    getAmountPlaceHolder() {
      if (this.selectedBank) {
        return `Min: ${this.selectedBank?.min_pay || '10'}`;
      }
      return this.$t('deposit-labelAmount');
    },
    selectedNetwork: {
      get() {
        return this.$store.state.deposit.selectedNetwork;
      },
      set(value) {
        this.$store.commit('deposit/onChangeSelectedNetwork', value);
      }
    },
    issuersModalStyle() {
      return { maxHeight: this.windowHeight, minHeight: '500px' };
    },
    hideIssuersModal: {
      get() {
        return this.$store.state.deposit.hideIssuersModal;
      },
      set(value) {
        this.$store.commit('deposit/onChangeHideIssuersModal', value);
      }
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('deposit', ['GET_DEPOSIT_PROVIDERS', 'GET_ACCOUNT_DETAIL_DEPOSIT', 'GET_CURRENCY_BALANCE', 'FETCH_TRANSFER', 'POST_CREATE_DEPOSIT', 'CANCEL_DEPOSIT', 'GET_HISTORY', 'GENERATE_ADDRESS']),
    ...mapActions('account', ['GET_GATEWAY_LIST']),
    getCoinSelection() {
      const currency = this.selectedCurrency.code.toLowerCase();
      this.GET_HISTORY({ currency, isNextPage: false, user_id: this.user.id });
      this.getProviders();
      this.GET_CURRENCY_BALANCE(currency);
      this.selectedBank = null;
      this.$refs.form?.reset();
    },
    fetchTransfer() {
      this.FETCH_TRANSFER({
        provider_id: this.selectedBank.id,
        currency: this.selectedCurrency.code
      });
    },
    onNetworkChange() {
      this.amount = null;
      this.selectedBank = '';
      this.$refs.form?.reset();
    },
    askForDepositApproval() {
      const minToDeposit = this.selectedBank?.min_pay || 10;
      if (parseFloat(this.amount) < minToDeposit) {
        Vue.$toast.error(
          this.$t('deposit-invalidAmount', {
            amount: '10€'
          })
        );
        return;
      }
      Swal.fire({
        title: this.$t('modal-title-warning'),
        text: this.$t('deposit-askForUserApproval'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes'),
        cancelButtonText: this.$t('commons.cancel')
      }).then((result) => {
        if (result.value) {
          this.approveDeposit();
        }
      });
    },
    showPopup() {
      if (this.selectedBank.bank_code === 'sepa') {
        // this.popupMessage = `We ONLY accept fundings from a bank account under your own name.
        //     Multiple deposits of the same amounts within a few days of each other may be
        //     delayed. If you must make multiple deposits, consider sending different
        //     amounts.
        //     You MUST include the Reference Code in your deposit in order to credit your account!
        //     Reference Code: 12345;\n
        //     This code identifies your deposit with your account. Include this code when submitting the wire transfer.`;
        this.popupVisible = true;
      } else {
        this.popupVisible = false;
      }
    },
    closePopup() {
      this.popupVisible = false;
    },
    referenceNumberGenerator() {
      return 'PAY' + Math.floor(100000 + Math.random() * 900000) + '' + Math.floor(100000 + Math.random() * 900000);
    },
    generateReferenceNumber() {
      this.referenceNumber = this.referenceNumberGenerator();
    },
    approveDeposit() {
      this.user.referenceNumber = this.referenceNumber;
      this.POST_CREATE_DEPOSIT(this.user)
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelDeposit() {
      this.CANCEL_DEPOSIT();
    },
    async copyText(text) {
      if (!text) return;
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.info(this.$t('toast-copyText'));
      } catch (error) {
        console.log('copyText error: ', error);
        this.$toast.error(this.$t('service.fatal_error'));
      }
    },
    async generateAddress() {
      let params = {
        id: this.user.id,
        asset_id: this.selectedCurrency.asset_name.asset_id
      };
      if(this.selectedCurrency.code.toLowerCase() === 'usdt') {
        params.asset_id = this.selectedNetwork.asset_id;
      }

      await this.GENERATE_ADDRESS(params);
      setTimeout(() => {
        Vue.$toast.info(this.$t('toast-address-generated'));
        this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
        window.location = '/wallet';
      }, 2000);
    },
    onChangeCountry() {
      this.selectedBank = null;
      this.$refs.form?.reset();
      this.getProviders();
    },
    getProviders() {
      const currency = this.selectedCurrency.code.toLowerCase();
      const country = this.selectedCountry;
      if (this.selectedCurrency.crypto) {
        this.GET_DEPOSIT_PROVIDERS({ currency, country_id: '' });
        return;
      }
      if (currency && country?.id) {
        this.GET_DEPOSIT_PROVIDERS({ currency, country_id: country?.id, user_id: this.user.id });
      }
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`;
    },
    getWindowHeight() {
      this.windowHeight = `${window.innerHeight - 200}px`;
    },
    showIssuersModal(currency) {
      if (currency && currency === 'EUR' && !this.hideIssuersModal) {
        this.issuersModal = true;
      }
    }
  },
  async created() {
    await this.GET_CURRENCY();
    await this.$store.dispatch('customer/GET_COUNTRIES');
    if (!this.selectedCountry && this.countries.length > 0) {
      this.selectedCountry = this.countries[0];
    }
    this.getProviders();
    this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
    this.GET_GATEWAY_LIST({ id: this.user.id });
    this.showIssuersModal(this.selectedCurrency.code);
  },
  mounted() {
    this.getWindowHeight();
    this.generateReferenceNumber();
    this.GET_ACCOUNT_DETAIL_DEPOSIT();
    window.addEventListener('resize', this.getWindowHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowHeight);
  },
  watch: {
    selectedCurrency: function (val) {
      this.showIssuersModal(val.code);
    }
  }
};
</script>

<style lang="scss" scoped>
.walletAddress {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  height: 40px;

  &:hover {
    overflow: auto;
    white-space: inherit;
    text-overflow: unset;
  }
}

.add-bank {
  cursor: pointer;
  color: #1b65d3;

  @media only screen and (max-width: 768px) {
    // padding: 20px 0 20px 5px;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .nav.nav-tabs {
    height: 40px;
    margin-bottom: 10px;

    .nav-item {
      height: 40px;

      a {
        line-height: 20px;
      }
    }
  }

  #bankLogo {
    width: 100%;
  }
}
p.set-color {
  font-weight: 700;
  color: red;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
