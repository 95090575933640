/* eslint-disable no-debugger */
import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
//import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue';
import Wallet from '../views/Wallet.vue';
import Deposit from '../views/Deposit.vue';
import InstantTrade from '../views/InstantTrade.vue';
import Withdrawal from '../views/Withdrawal.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import PasswordReset from '../views/PasswordReset.vue';
import AccountSettings from '../views/Personal/AccountSettings.vue';
import BankSettings from '../views/Personal/BankSettings.vue';
// import History from '../views/Personal/History.vue';
import NotificationSettings from '../views/Personal/NotificationSettings.vue';
import PasswordSettings from '../views/Personal/PasswordSettings.vue';
import UserLoginSettings from '../views/Personal/UserLoginSettings.vue';
import Limit from '../views/Personal/Limit.vue';
import EmailVerify from '../views/EmailVerify.vue';
import SpotTrade from '../views/SpotTrade.vue';
/* import Campaigns from '../views/Campaigns.vue'; */
// import TwoFAGoogleAuth from '../views/Personal/TwoFAGoogleAuth.vue'
import KycSettings from '../views/Personal/KycSettings.vue';

import API from '../api';
import Services from '../config/_axios';

import i18n from '../config/_i18n';
import isDev from '../config/isDev';
import restrictedRoutesInProd from '../config/restrictedProdRoutes';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.remove('fixed-navbar');
    localStorage.removeItem('refreshNextTime');
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

const ifAuthenticatedAndNotLevelTwo = (to, from, next) => {
  if (
    to.name !== 'AccountSettings' &&
    store.getters['customer/isAuthenticated'] &&
    store.getters['customer/userLevel'] < 30
  ) {
    Vue.$toast.error(i18n.t("userLevelWarning"));
    next('/personal/account-settings');
    return;
  } else if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/instant-trade',
    name: 'InstantTrade',
    component: InstantTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/Register/EmailVerify',
    name: 'EmailVerify',
    component: EmailVerify,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/personal/kyc-settings',
    name: 'KycSettings',
    component: KycSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/bank-settings',
    name: 'BankSettings',
    component: BankSettings,
    beforeEnter: ifAuthenticated
  },
  /* {
    path: '/personal/history',
    name: 'History',
    component: History,
    beforeEnter: ifAuthenticated
  }, */
  {
    path: '/personal/notification-settings',
    name: 'NotificationSettings',
    component: NotificationSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/password-settings',
    name: 'PasswordSettings',
    component: PasswordSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-login-settings',
    name: 'UserLoginSettings',
    component: UserLoginSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-limit',
    name: 'UserLimit',
    component: Limit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/spot-trade',
    name: 'SpotTrade',
    component: SpotTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  /* {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  }, */
  /* {
    path: '/personal/two-fa-google-auth',
    name: 'TwoFAGoogleAuth',
    component: TwoFAGoogleAuth,
    beforeEnter: ifAuthenticated
  }, */
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes
});

router.afterEach(() => {
  let refreshNextTime = window.localStorage.getItem('refreshNextTime');
  refreshNextTime = parseInt(refreshNextTime);

  if (refreshNextTime && refreshNextTime < Date.now()) {
    setTimeout(() => {
      Services.post(API.refreshToken)
        .then((res) => {
          localStorage.setItem('userToken', res.data.access_token);
          localStorage.setItem('refreshNextTime', Date.now() + 3600000);
          Services.defaults.headers.common['Authorization'] = res.data.access_token;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            console.log(err.response.data.message);
          }
        });
    }, 3000);
  }
});

router.beforeEach((to, from, next) => {
  if(!isDev && restrictedRoutesInProd.some(val => to.path.indexOf(val) !== -1)) {
    next("/");
  } else {
    next();
  }
  if (store.getters['customer/isAuthenticated']) {
    store.dispatch('customer/GET_USER_NOTIFICATIONS', { isNextPage: false })
  }

});


export default router;
