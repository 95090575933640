/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import { OrderDirection } from '../../config/order.enum';

const quicksale = {
  state: () => ({
    isLoading: true,
    quicksale: {
      crypto: null,
      currency: null,
      type: 10,
      total: null,
      amount: null,
      raw: null
    },
    buyNow: {
      availableBalance: 0,
      priceSell: 0,
      summary: null,
      showSummaryModal: false,
      staticContent: null
    },
    sellNow: {
      availableBalance: 0,
      lowestBuy: 0,
      highestBuy: 0,
      priceBuy: 0,
      showSummaryModal: false,
      staticContent: null
    },
    history: [],
    next: '',
    openOrders: [],
    completedOrders: []
  }),
  mutations: {
    SET_CALCULATE(state, payload) {
      state.quicksale.fee = payload.payload.fee_formatted;
      if (payload.calcType === 'total') {
        state.quicksale.amount = payload.payload.amount;
      } else {
        state.quicksale.total = payload.payload.total_decimal;
      }
    },
    // eslint-disable-next-line no-unused-vars
    SET_BUY(state, payload) {
      state.buyNow.summary = payload;
      state.buyNow.showSummaryModal = true;
    },
    SET_SELL(state, payload) {
      state.sellNow.summary = payload;
      state.sellNow.showSummaryModal = true;
    },
    SET_TRY_BALANCE(state, payload) {
      state.buyNow.availableBalance = payload.approximate_balance_formatted;
    },
    SET_CURRENCY_BALANCE(state, payload) {
      state.sellNow.availableBalance = payload.data.available_formatted;
    },
    SET_BUY_NOW_PRICES(state, payload) {
      state.buyNow.priceSell = payload.price_buy_formatted;
    },
    SET_SELL_NOW_PRICES(state, payload) {
      state.sellNow.lowestBuy = payload.lowest_sale_formatted;
      state.sellNow.highestBuy = payload.highest_sale_formatted;
      state.sellNow.priceBuy = payload.price_sell_formatted;
    },
    SET_HISTORY(state, payload) {
      // state.history = payload;
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    CLEAR_FORM(state) {
      state.quicksale.total = null;
      state.quicksale.amount = null;
      state.quicksale.fee = null;
    },
    closeModal(state, playload) {
      state.buyNow.showSummaryModal = playload;
      state.buyNow.summary = null;
      state.sellNow.showSummaryModal = playload;
      state.sellNow.summary = null;
    },
    updateQuicksaleType(state, value) {
      state.quicksale.type = value;
    },
    SET_STATIC_CONTENT_BUY(state, payload) {
      state.buyNow.staticContent = payload;
    },
    SET_STATIC_CONTENT_SELL(state, payload) {
      state.sellNow.staticContent = payload;
    },
    SET_QUICK_SELL_RAW(state, payload) {
      state.quicksale.raw = payload;
    },
    SET_QUICKSALE_AMOUNT(state, payload) {
      state.quicksale.amount = payload;
    },
    SET_QUICKSALE_TOTAL(state, payload) {
      state.quicksale.total = payload;
    }
  },
  actions: {
    POST_CALCULATE({ state, commit }, params) {
      const data = {
        type: params.buy
      };
      if (params.calcType === 'amount') data.amount = state.quicksale.amount;
      if (params.calcType === 'total') data.total = state.quicksale.total;
      const endpoint = `${API.buyAndSellNowPrices}${state.quicksale.crypto.code}/calculate?base_currency=${state.quicksale.currency.code}`;
      Services.post(endpoint, data)
        .then(res => {
          commit('SET_CALCULATE', { calcType: params.calcType, payload: res.data });
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async POST_BUY({ state, commit }) {
      var order = {
        order_id: null,
        price: null,
        amount: state.quicksale.total,
        direction: 0,
        symbol: null,
        type: 1,
        pair: state.quicksale.crypto.code.toUpperCase() + '/' + state.quicksale.currency.code.toUpperCase(),
        status: null,
        canceled_time: null,
        completed_time: null,
        member_id: null,
        time: null,
        traded_amount: null,
        turnover: null,
        use_discount: null,
        base_symbol: null,
        coin_symbol: null
      };

      Services.post(await API.spotTrade.createOrder, order)
        .then(res => {
          commit('SET_BUY', res.data);
          Vue.$toast.success('Order Completed');
          commit('CLEAR_FORM');
          commit('IS_LOADING', false);
          // this.dispatch('quicksale/FETCH_ORDER_HISTORY');
          // this.dispatch('quicksale/GET_CURRENCY_BALANCE');
          window.location = '/instant-trade';
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async POST_SELL({ state, commit }) {
      var order = {
        order_id: null,
        price: null,
        amount: state.quicksale.amount,
        direction: 1,
        symbol: null,
        type: 1,
        pair: state.quicksale.crypto.code.toUpperCase() + '/' + state.quicksale.currency.code.toUpperCase(),
        status: null,
        canceled_time: null,
        completed_time: null,
        member_id: null,
        time: null,
        traded_amount: null,
        turnover: null,
        use_discount: null,
        base_symbol: null,
        coin_symbol: null
      };
      Services.post(await API.spotTrade.createOrder, order)
        .then(res => {
          commit('SET_SELL', res.data);
          Vue.$toast.success('Order Completed');
          commit('CLEAR_FORM');
          commit('IS_LOADING', false);
          // this.dispatch('quicksale/FETCH_ORDER_HISTORY');
          // this.dispatch('quicksale/GET_CURRENCY_BALANCE');
          window.location = '/instant-trade';
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async FETCH_ORDER_HISTORY({ state }) {
      let url = `${API.spotTrade.orderHistory}?pair=${state.quicksale.crypto.code.toUpperCase() + '/' + state.quicksale.currency.code.toUpperCase()}`;

      const orderHistory = await Services.get(url);
      const fetchedOpenOrders = orderHistory.data.open_orders;

      fetchedOpenOrders.forEach(openOrder => {
        const isCancelled = openOrder.canceled_time > 0;
        openOrder.status = isCancelled ? 2 : openOrder.status;
      });

      state.openOrders = fetchedOpenOrders;
      state.completedOrders = orderHistory.data.completed_orders;
    },
    async SET_PAIR_BALANCE_BY_DIRECTION({ state }) {
      try {
        let pair = { quoteCurrency: state.selectedPair.quoteCurrency, baseCurrency: state.selectedPair.baseCurrency };
        if (state.selectedOrderDirection === OrderDirection.Sell) {
          pair = { quoteCurrency: state.selectedPair.baseCurrency, baseCurrency: state.selectedPair.quoteCurrency };
        }
        const selectedPairBalance = await this.dispatch('spottrade/FETCH_CURRENCY_BALANCE', pair);
        state.pairBalance = selectedPairBalance;
      } catch (error) {
        console.log(error);
      }
    },
    async GET_CURRENCY_BALANCE({ commit, state }) {
      Services.get(`${API.currencyBalance}${state.quicksale.crypto.code}?base_currency=${state.quicksale.currency.code}`)
        .then(res => {
          commit('SET_CURRENCY_BALANCE', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_BUY_NOW_PRICES({ commit, state }) {
      Services.get(`${API.buyAndSellNowPrices}${state.quicksale.crypto.code}?base_currency=${state.quicksale.currency.code}`)
        .then(res => {
          commit('SET_QUICK_SELL_RAW', res.data);
          commit('SET_BUY_NOW_PRICES', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_SELL_NOW_PRICES({ commit, state }) {
      Services.get(`${API.buyAndSellNowPrices}${state.quicksale.crypto.code}?base_currency=${state.quicksale.currency.code}`)
        .then(res => {
          commit('SET_QUICK_SELL_RAW', res.data);
          commit('SET_SELL_NOW_PRICES', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_HISTORY() {
      this.dispatch('quicksale/FETCH_ORDER_HISTORY');
      this.dispatch('quicksale/SET_PAIR_BALANCE_BY_DIRECTION');
    },
    GET_STATIC_CONTENT_BUY({ state, commit }) {
      if (state.buyNow.staticContent) return;
      Services.get(API.quicksaleBuyContent)
        .then(res => {
          commit('SET_STATIC_CONTENT_BUY', res.data.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_STATIC_CONTENT_SELL({ state, commit }) {
      if (state.sellNow.staticContent) return;
      Services.get(API.quicksaleSellContent)
        .then(res => {
          commit('SET_STATIC_CONTENT_SELL', res.data.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  },
  getters: {
    openOrders: state => state.openOrders,
    completedOrders: state => state.completedOrders
  },
  namespaced: true
};

export default quicksale;
