import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/languages/en.json';
import nl from '@/assets/languages/nl.json';

import { localeChanged } from 'vee-validate';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';

Vue.use(VueI18n);

const qs = new URLSearchParams(window.location.search);

if (qs.has('lang')) {
  sessionStorage.setItem('lang', qs.get('lang'));
}

let lang = sessionStorage.getItem('lang');
if (!lang) {
  lang = 'en';
  sessionStorage.setItem('lang', lang);
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: {
    en: { ...en, validation: MessagesEN.messages },
    nl: { ...nl, validation: MessagesNL.messages }
  }
});
document.documentElement.setAttribute('lang', lang);

export function changeLanguages(lang, refresh) {
  sessionStorage.setItem('lang', lang);
  i18n.locale = lang;
  localeChanged();
  if (refresh) {
    window.history.pushState({}, document.title, window.location.pathname);
    location.reload();
  }
}

export default i18n;
