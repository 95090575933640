<template>
  <div class="content-wrapper">
    <div class="row" v-if="!isMobile">
      <div class="col-12 text-center mb-5">
        <router-link to="/">
          <img :src="logoDefault" class="card-account-img" alt="card-account-img" />
        </router-link>
      </div>
      <div class="col-10 offset-1 col-md-6 offset-md-3">
        <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
          <div class="card-content">
            <div class="card-body p-md-3" v-if="verfiyEmail">
              <div class="d-sm-block d-md-none text-center pb-1">
                <router-link to="/"
                  ><img class="brand-logo" alt="Miracle Cash&More" :src="logoMobile"
                /></router-link>
              </div>
              <p class="text-center h5 mb-3">{{ $t('emailVerifyMessage') }}</p>
              <router-link class="btn btn-primary round btn-block" to="/">{{ $t('modal-btn') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh;" v-else>
      <div>
        <img :src="logoDefault" class="card-account-img" alt="card-account-img" />
      </div>
      <h3 v-if="isLoading">Loading...</h3>
      <div v-else-if="!isLoading && appStatus !== 'installed'" class="d-flex flex-column align-items-center" style="row-gap: 8px;">
        <p class="h3 font-weight-normal" style="margin-bottom: 16px;">If <span class="font-weight-bold">Miracle Cash&More Netherlands</span> app is not installed on your device</p>
        <a href="https://apps.apple.com/nl/app/miracle-cash-more-nl/id6498934699?l=en-GB" target="_blank" class="store-download-btn">
          <AppStoreSvg height="24px" width="24px" />
          Download On The App Store
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.miraclecashapp.nl&hl=en_IN" target="_blank" class="store-download-btn">
          <PlayStoreSvg height="24px" width="24px" />
          Get It On Play Store
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppStoreSvg from '../components/icons/AppStoreSvg.vue';
import PlayStoreSvg from '../components/icons/PlayStoreSvg.vue';

export default {
  name: 'EmailVerify',
  components: {
    AppStoreSvg,
    PlayStoreSvg
  },
  data: () => ({
    isLoading: false,
    appStatus: 'installed'
  }),
  computed: {
    ...mapState({
      verfiyEmail: (state) => state.customer.verifyEmail
    }),
    isMobile() {
      const getType = this.getParameterByName('type');
      return getType ? getType === 'mobile' : false;
    }
  },
  methods: {
    ...mapActions('customer', ['VERIFY_EMAIL']),
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  },
  created() {
    const id = this.getParameterByName('id');
    const verify_code = this.getParameterByName('verify_code');
    if(this.isMobile) {
      this.isLoading = true;
      this.appStatus = 'installed';
      // redirect to mobile-app
      const deppLinkUrl = `miraclenl-app://verify-email/${verify_code}/${id}`;
      window.location.href = deppLinkUrl;
      // Use a timeout to check if the app opened
      setTimeout(() => {
        this.isLoading = false;
        this.appStatus = 'not-installed';
        // alert('app is not installed on your device');
      }, 1000);

    } else {
      this.VERIFY_EMAIL({ id, verify_code });
    }
  },
  beforeCreate() {
    document.querySelector('body').classList.remove('fixed-navbar');
  }
};
</script>

<style lang="scss" scoped>
.card-account-img {
  max-width: 200px;
  width: 100% !important;
  padding-right: 20px;
}
.store-download-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 25px;
  min-width: 375px;
  padding: 8px 16px;
  border: 1px solid gray;
  &:hover {
    background: #642583;
    color: white !important;
  }
}
</style>
