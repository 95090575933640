<template>
    <div class="container-fluid mt-1 mb-3">        
        <crypto-widget type="ticker-quotes" template="chart" color="grey" :assets="bitdenexAssets" speed="50" direction="left" pause="true" api="cryptocompare.com" realtime="true" animation="zoomInLeft"></crypto-widget>
    </div>
</template>

<script>
export default {
    name: "Marquee",
    data: () => ({
    }),
    computed: {
      bitdenexAssets() {
        const assets = [
          "BTC", "ETH", "USDT", "LTC", "TRX", "XRP", "BNB", "XLM",
          "LINK", "FTM", "GRT", "ENJ", "CHZ", "OMG", "LRC", "COMP",
          "SNX", "QNT", "BAT", "BNT", "SRM", "OGN", "OXT", "ADA",
          "DOGE", "MATIC", "SOL", "AVAX", "DOT", "ETC", "SHIB",
          "OCEAN", "XTZ", "ALGO", "HBAR", "NEAR", "BCH", "MANA",
          "AAVE", "APE", "IMX", "AXS", "SAND", "RNDR", "1INCH",
          "ANKR", "GALA", "BAL", "GNO"
        ];
        return assets.map(val => `${val}~EUR`).join(',');
      },
    },
    methods: {
    createScript(url) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    },
    createLink(url) {
        let head  = document.getElementsByTagName('head')[0];
        let link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        head.appendChild(link);
    },
  },
  created() {
    const body = document.body;
    body.classList.add("aside-minimize");

    this.createLink('../widgets/assets/css/style.css');
    this.createScript('../widgets/assets/js/dist/app.js');
  },
};
</script>

<style scoped>
</style>