<template>
  <transition name="fade">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(createWallet)">
        <div class="modal show">
          <div class="modal__backdrop" @click="closeModal()" />
          <div class="modal__dialog">
            <div class="modal__header">
              <h1>{{ title }}</h1>
              <div class="modal__close" @click="closeModal()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </div>
            </div>
            <div class="modal__body">
              <div class="form-group" v-if="!showAddBankAccount">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-label-selectCurrency')">
                  <fieldset>
                    <label class="mr-sm-2">{{ $t('withdrawal-label-selectCurrency') }}</label>
                    <multiselect v-select-overflow v-model="selectedCurrency" id="currency" track-by="code" label="name" :placeholder="$t('bankSettings-chooseCurrency')" :options="getCurrency" :searchable="true" :allow-empty="false" :custom-label="currencySelectCustomLabel" :show-labels="false" :class="classes" open-direction="bottom">
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </fieldset>
                </validation-provider>
              </div>
              <!-- <div v-if="selectedCurrency"> -->
              <div class="form-group" v-if="!showAddBankAccount && selectedCurrency.code === 'USDT'">
                <label class="mr-sm-2">{{ $t('network') }}</label>
                <validation-provider rules="required" v-slot="{ classes, errors }">
                  <div class="input-group">
                    <multiselect v-model="selectedNetwork" deselect-label="" select-label="" :class="classes" selected-label="" track-by="name" :label="$t('network')" :options="networks" :allow-empty="false" open-direction="bottom">
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} </template>
                      <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                      <template slot="noResult">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <!-- </div> -->
              <div class="form-group">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="showAddBankAccount ? $t('withdrawal-accountName') : $t('withdrawal-addCryptoWalletLabel')">
                  <label class="mr-sm-2">
                    {{ showAddBankAccount ? $t('withdrawal-accountName') : $t('withdrawal-addCryptoWalletLabel') }}
                  </label>
                  <div class="input-group" :class="classes">
                    <input type="text" name="walletName" v-model="add.label" class="form-control" :class="classes" :placeholder="showAddBankAccount ? $t('withdrawal-accountName') : $t('withdrawal-addCryptoWalletLabel')" />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group" v-if="!showAddBankAccount">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-addCryptoWalletAddress')">
                  <label class="mr-sm-2">{{ $t('withdrawal-addCryptoWalletAddress') }}</label>
                  <div class="input-group" :class="classes">
                    <input type="text" name="address" v-model="add.address" class="form-control" :class="classes" :placeholder="$t('withdrawal-enterWalletAddress')" />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group" v-if="!showAddBankAccount && selectedCurrency.code === 'XRP'">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-walletTag')">
                  <label class="mr-sm-2">{{ $t('withdrawal-walletTag') }}</label>
                  <div class="input-group" :class="classes">
                    <input type="text" name="tag" v-model="add.tag" class="form-control" :class="classes" :placeholder="$t('withdrawal-enterTargetValue')" />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group" v-if="!showAddBankAccount && selectedCurrency.code === 'XLM'">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-memoAdres')">
                  <label class="mr-sm-2">{{ $t('withdrawal-memoAdres') }}</label>
                  <div class="input-group" :class="classes">
                    <input type="text" name="tag" v-model="add.tag" class="form-control" :class="classes" :placeholder="$t('withdrawal-enterMemoValue')" />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <validation-provider ref="address-proof-ref" :name="$t('withdrawal-addCryptoWalletAddress')" v-slot="{ classes, errors }" class="form-group" v-if="!showAddBankAccount">
                <label class="mr-sm-2">{{ $t('withdrawal-addressProof') }}</label>
                <div class="input-group" :class="classes">
                  <input type="file" name="address" @change="handleFileUpload" :class="classes" class="form-control" :placeholder="$t('withdrawal-addressProof')" />
                </div>
                <span class="error__message">{{ errors[0] }}</span>
                <div class="d-flex w-100 mt-1">
                  <div class="alert alert-warning" role="alert">
                    {{ $t('proof_of_address_input_warning') }}
                  </div>
                </div>
              </validation-provider>

              <div class="form-group" v-if="showAddBankAccount">
                <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-bank')">
                  <fieldset>
                    <label class="mr-sm-2">{{ $t('withdrawal-label-currenciestype') }}</label>
                    <multiselect v-select-overflow v-model="add.currency" id="bank" track-by="code" label="name" :placeholder="$t('bankSettings-chooseCurrency')" :options="getCurrency" :searchable="true" :allow-empty="false" :class="classes" open-direction="bottom">
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </fieldset>
                </validation-provider>
              </div>
              <div class="form-group" v-if="showAddBankAccount">
                <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }">
                  <fieldset>
                    <label class="mr-sm-2">{{ $t('bankSettings-bank') }}</label>
                    <multiselect v-select-overflow v-model="add.bank" deselect-label="" select-label="" selected-label="" id="banks" track-by="name" label="name" :placeholder="$t('bankSettings-chooseBank')" :options="banks" :searchable="true" :allow-empty="false" :class="classes" open-direction="bottom">
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.short}` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.short}` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </fieldset>
                </validation-provider>
              </div>
              <div class="form-group" v-if="showAddBankAccount">
                <validation-provider
                  :rules="{
                    regex: /^([ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/gm
                  }"
                  v-slot="{ classes, errors }"
                  name="IBAN"
                >
                  <label class="mr-sm-2">{{ add.bank.type === 'UBAN' ? $t('bankSettings-uban') : $t('bankSettings-iban') }}</label>
                  <div class="input-group">
                    <div class="iban-country-code">{{ ibanCountryCode }}</div>
                    <input v-model="add.address" :class="classes" class="form-control iban-input" :placeholder="add.bank.type === 'UBAN' ? $t('withdrawal-enterUBAN') : $t('withdrawal-enterIBAN')" v-uppercase />
                    <span class="error__message">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="col-12 text-center mt-2">
                <button type="submit" class="btn-gradient-primary btn-block">
                  {{ $t('withdrawal-save') }}
                </button>
              </div>
            </div>
            <div class="modal__footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </transition>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
// import AWS from 'aws-sdk';
export default {
  name: 'AddCryptoWalletModal',
  props: {
    title: String,
    datas: Object
  },
  components: {
    Multiselect
  },
  data() {
    return {
      ibanCountryCode: '',
      selectedFile: null
    };
  },
  computed: {
    ...mapState({
      add: (state) => state.withdrawal.add,
      user: (state) => state.customer.user,
      banks: (state) => state.withdrawal.banks,
      bank_settings: (state) => state.account.bank_settings,
      selectedCurrency: (state) => state.withdrawal.selectedCurrency,
      isLoading: (state) => state.global.isLoading,
      showAddBankAccount: (state) => state.withdrawal.showAddBankAccount,
      networks: (state) => state.withdrawal.networks
    }),
    ...mapGetters('global', ['getCurrency']),
    selectedCurrency: {
      get() {
        return this.$store.state.withdrawal.selectedCurrency;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedCurrency', value);
      }
    },
    selectedNetwork: {
      get() {
        return this.$store.state.withdrawal.selectedNetwork;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedNetwork', value);
      }
    }
  },
  methods: {
    ...mapActions('withdrawal', ['POST_ADD_WALLET', 'GET_BANKS', 'UPLOAD_DOCUMENT_WITHDRAW']),
    closeModal() {
      this.$store.commit('withdrawal/CLEAR_WALLET_FORM', false);
      this.$store.commit('withdrawal/showAddWalletModalUpdate', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      document.querySelector('body').classList.add('overflow-hidden');
    },
    createWallet() {
      const file = this.bank_settings.imageUrl;
      if (!file) {
        this.$refs.form?.setErrors({ [this.$t('withdrawal-addCryptoWalletAddress')]: this.$t('veeValidateExtraMsgs.required_field') });
        return;
      }
      this.UPLOAD_DOCUMENT_WITHDRAW({ id: this.user.id, file });

      // const file = this.selectedFile;
      // if (!file) {
      //   this.$refs.form?.setErrors({ [this.$t('withdrawal-addCryptoWalletAddress')]: this.$t('veeValidateExtraMsgs.required_field') });
      //   return;
      // }

      // AWS.config.update({
      //   region: 'eu-central-1'
      // });
      // var s3 = new AWS.S3({
      //   apiVersion: '2006-03-01',
      //   accessKeyId: process.env.VUE_APP_AWS_KEY,
      //   secretAccessKey: process.env.VUE_APP_AWS_SECRET
      // });

      // const params = {
      //   ACL: 'public-read',
      //   Bucket: 'getsuripay',
      //   Key: 'miracle/' + this.user.id + '/whitelist_address/' + file.name,
      //   Body: file
      // };
      // s3.upload(params, (err, data) => {
      //   if (err) {
      //     console.error('Error:', err);
      //   } else {
      //     this.add.imageUrl = data.Location;
      //     this.POST_ADD_WALLET();
      //   }
      // });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if(file) {
        this.$refs['address-proof-ref']?.reset();
      }
      this.selectedFile = file;
      this.bank_settings.imageUrl = file;
    },
    uploadFile() {
      if (this.selectedFile) {
        // event.target.files[0]
        // Perform your file upload logic here
        // You can use libraries like axios to send the file to the server
        // Example: axios.post(‘/api/upload’, this.selectedFile)
        console.log('File uploaded:', this.selectedFile);
      } else {
        console.log('No file selected');
      }
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`;
    },
  },
  directives: {
    uppercase: {
      update(el) {
        el.value = el.value.toUpperCase();
      }
    }
  },
  watch: {
    'add.bank': {
      deep: true,
      handler(bank) {
        let addressPrefix = bank.country.code;
        if (bank.type === 'UBAN') addressPrefix = 'ct';
        this.ibanCountryCode = addressPrefix;
      }
    }
  },
  created() {
    if (this.showAddBankAccount) {
      this.GET_BANKS();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;

  &.show {
    display: block;
  }

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    outline: 0;
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__footer {
    padding: 10px 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
