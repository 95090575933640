const state = {
  isLoading: false
};

const mutations = {
  SHOW_LOADER(state) {
    state.isLoading = true;
  },
  HIDE_LOADER(state) {
    state.isLoading = false;
  }
};

const actions = {
  showLoader({ commit }) {
    commit('SHOW_LOADER');
  },
  hideLoader({ commit }) {
    commit('HIDE_LOADER');
  }
};

const getters = {
  isLoading: (state) => state.isLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
