<template>
    <div class="table-responsive col-2 card m-0 p-0 h-100 overflow-scroll">
        <div class="p-1">
            <div class="position-relative d-flex align-items-center">
                <input type="text" class="form-control input-sm search-inp" v-model="pairSearchKeyword" :placeholder="$t('commons.search')" />
                <button class="btn btn-icon bg-transparent position-absolute d-flex align-items-center search-inp-close" v-if="pairSearchKeyword" @click="pairSearchKeyword = ''">
                    <i class="fa fa-times-circle text-primary"></i>
                </button>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('spotTrade.pair') }}</th>
                    <th class="text-right">{{ $t('commons.price') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(pair, index) in filteredPairs" :key="'pair-' + index" @click="onPairSelected(pair)"
                    @mouseover="onMouseOver(pair)" @mouseleave="onMouseLeave(pair)"
                    :class="{ 'pair-hover': pair.id == hoveredPair }">
                    <td class="text-center">{{ pair.name }}</td>
                    <td class="text-right">{{ pair.price | currency('', 6) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import currency from '@/config/_currency';

export default {
    name: 'RightBlock',
    data() {
        return {
            hoveredPair: "",
            pairSearchKeyword: ""
        }
    },
    filters: {
        currency
    },
    computed: {
        ...mapGetters('spottrade', ['allPairs']),
        filteredPairs() {
            return this.allPairs.filter((_pair) => _pair.name.toLowerCase().includes(this.pairSearchKeyword.toLowerCase()) || _pair.price.toLowerCase().includes(this.pairSearchKeyword.toLowerCase()));
        }
    },
    methods: {
        ...mapActions('spottrade', ['SELECT_PAIR', 'SET_PAIR_BALANCE_BY_DIRECTION']),
        onPairSelected(pair) {
            this.SELECT_PAIR(pair);
            this.SET_PAIR_BALANCE_BY_DIRECTION();
        },
        onMouseOver(pair) {
            this.hoveredPair = pair.id;
        },
        onMouseLeave() {
            this.hoveredPair = "";
        }

    }
};
</script>
<style lang="scss" scoped>
.pair-hover {
    cursor: pointer;
    color: #000;
    text-shadow: 0.5px 0.5px;
}
.search-inp {
    max-height: 42.8px;
    min-width: 150px;
    width: auto;
    height: auto;
}
.search-inp-close {
    right: 5px;
    padding: 0;
}
</style>