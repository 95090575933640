<template>
  <div>
      <!-- <OrdersTable
          :orders="openOrders"
          title="spotTrade.open-orders"
          :selectable=true
      /> -->
      <OrdersTable
          :orders="completedOrders"
          title="spotTrade.order-history"
      />
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import OrdersTable from './OrdersTable';

export default {
  name: "History",
  components: { OrdersTable },
  computed: {
      ...mapGetters("quicksale", ["openOrders", "completedOrders"])
  },
  methods: {
    ...mapActions('quicksale', ['GET_HISTORY']),
  },
  created() {
    this.GET_HISTORY();
  }
};

</script>