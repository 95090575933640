<template>
  <div class="wrapper">
    <span v-for="(file, index) in files" :key="`file-${index}`"
        class="w-100 d-flex justify-content-between align-items-center"
        :class="{'mb-1': files.length > 1}"
    >
      {{ file.name }} ({{ getFileSize(file?.size) }})
      <button class="btn btn-sm btn-outline-danger" @click.prevent="$emit('onRemoveFile', index)">
        <i class="la la-close font-small-2"></i>
      </button>
    </span>
  </div>
</template>
<script>
import utils from '../../config/utils';
export default {
  name: 'FileCardRow',
  props: ['files'],
  methods: {
    getFileSize: utils.getFileSize,
  }
};
</script>
<style scoped>
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
