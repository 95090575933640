<template>
  <div>
    <div id="kyc-message" class="card-body" v-show="pending == 'block'">
      <div style="text-align: center">
        <p>Enable KYC access on your account to Enjoy more benefits.</p>
        <h1>KYC Verification is Pending</h1>
        <p>Only upload the valid documents (ID Card, Driving License or Passport) with the same name as per account.</p>
        <button-component id="startKyc" class="btn-gradient-primary my-1" v-if="showButton" @load-component="loadComponent" />
        <component :is="dynamicComponent" v-if="dynamicComponent" />
      </div>
    </div>

    <div id="kyc-message-complete" class="card-body" v-show="complete == 'block'">
      <div style="text-align: center">
        <p>KYC is complete now enjoy all benefits.</p>
        <h1>KYC Verification is Complete</h1>
      </div>
    </div>

    <div id="kyc-message-review" class="card-body" v-show="review == 'block'">
      <div style="text-align: center">
        <h1>Kyc Verification is in-review!</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Onfido from '../onfidoScreen/Onfido.vue';
import ButtonComponent from './ButtonComponent.vue';
//import Multiselect from 'vue-multiselect';

export default {
  name: 'SecondLevelUpgrade',
  components: {
    //Multiselect,
    'button-component': ButtonComponent,
    Onfido
  },
  data() {
    return {
      selectedCountry: null,
      selectedCity: null,
      selectedTown: null,
      gender: [
        { id: 'M', label: 'Male' },
        { id: 'F', label: 'Female' }
      ],
      dynamicComponent: null,
      showButton: true,
      pending: 'none',
      review: 'none',
      complete: 'none'
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.account.level_2.isLoading,
      countryCodes: (state) => state.account.countryCodes,
      twoNdLevel: (state) => state.account.twoNdLevel,
      formTwoData: (state) => state.account.formTwoData,
      cities: (state) => state.customer.cities,
      towns: (state) => state.customer.towns,
      user: (state) => state.customer.user,
      kycApplicantId: (state) => state.customer.kycApplicantId
    })
  },
  methods: {
    ...mapActions('account', ['GET_COUNTRY_CODES', 'GET_FORM_2ND_LEVEL', 'POST_2ND_LEVEL']),
    ...mapActions('customer', ['GET_CITIES', 'GET_TOWNS', 'CREATE_KYC_WORKFLOWRUN_ID']),
    loadComponent() {
      //var kyc_message = document.getElementById("kyc-message");
      //var kyc_message_complete = document.getElementById("kyc-message-complete");
      //var kyc_message_review = document.getElementById("kyc-message-review");

      console.log(this.kycApplicantId.kycStatus, 'Here');
      if (parseInt(this.kycApplicantId.kycStatus) === 3) {
        this.review = 'block';
        this.complete = 'none';
        this.pending = 'none';
      } else if (parseInt(this.user.level) === 30) {
        this.review = 'none';
        this.complete = 'block';
        this.pending = 'none';
      } else {
        this.review = 'none';
        this.complete = 'none';
        this.pending = 'block';
      }
      this.showButton = false;
      this.dynamicComponent = 'Onfido'; // Set the dynamic component name
    },
    renderForm(payload) {
      let content = {};
      Object.entries(payload.properties).forEach(([key, value]) => {
        if (key == 'City') content[key] = this.selectedCity.title;
        else if (key == 'StateProvinceCode') content[key] = this.selectedTown.title;
        else if (key == 'Gender') content[key] = this.twoNdLevel[key].id;
        else content[key] = value.type != 'object' ? this.twoNdLevel[key] : this.renderForm(value);
      });
      return content;
    },
    upgradeTwoNdLevel() {
      let contentDataFields = {};
      let obj = this.$store.state.account.responseNdLevel.DataFields.properties;
      Object.entries(obj).forEach(([key, value]) => {
        if (key == 'NationalIds') contentDataFields[key] = [this.renderForm(value)];
        else contentDataFields[key] = this.renderForm(value);
      });
      this.POST_2ND_LEVEL({ selectedCountry: this.selectedCountry.countryCode, content: contentDataFields });
    },
    isRequired(field) {
      let allRequired = this.$store.state.account.formTwoRequired;
      return allRequired.includes(field);
    },
    getFormToCountry() {
      if (this.selectedCountry) {
        this.GET_FORM_2ND_LEVEL(this.selectedCountry.countryCode);
        this.GET_CITIES(this.selectedCountry.countryCode);
      }
      this.selectedCity = null;
      this.selectedTown = null;
    },
    getTowns() {
      this.$nextTick(function () {
        this.GET_TOWNS(this.selectedCity.id);
      });
      this.selectedTown = '';
    },
    cancelUpgrade() {
      this.$store.commit('account/cancelUpgrade', false);
    },
    findMaxValue(field) {
      switch (field) {
        case 'DayOfBirth':
          return 31;
        case 'MonthOfBirth':
          return 12;
        case 'YearOfBirth':
          return new Date().getFullYear();
        default:
          return '';
      }
    },
    findMinValue(field) {
      switch (field) {
        case 'YearOfBirth':
          return 1920;
        default:
          return 1;
      }
    },
    formParentClass(item) {
      const nameFields = ['FirstGivenName', 'MiddleName', 'FirstSurName'];
      const birthDayFields = ['DayOfBirth', 'MonthOfBirth', 'YearOfBirth'];
      const buildingNumberFields = ['BuildingNumber', 'BuildingName', 'StreetName', 'UnitNumber'];
      const cityFields = ['BuildingNumber', 'BuildingName', 'StreetName', 'UnitNumber'];
      const tripleFields = nameFields.concat(birthDayFields, buildingNumberFields, cityFields);

      if (tripleFields.includes(item.name)) return 'col-md-4 triple';
    }
  },
  created() {
    console.log('Step 1');
    this.GET_COUNTRY_CODES();
    this.CREATE_KYC_WORKFLOWRUN_ID();
  },
  async mounted() {
    //var kyc_message = document.getElementById("kyc-message");
    //var kyc_message_complete = document.getElementById("kyc-message-complete");
    //var kyc_message_review = document.getElementById("kyc-message-review");

    console.log(this.kycApplicantId.kycStatus, 'Here');
    if (parseInt(this.kycApplicantId.kycStatus) === 3) {
      this.review = 'block';
      this.complete = 'none';
      this.pending = 'none';
    } else if (parseInt(this.user.level) === 30) {
      this.review = 'none';
      this.complete = 'block';
      this.pending = 'none';
    } else {
      this.review = 'none';
      this.complete = 'none';
      this.pending = 'block';
    }
  },
  destroyed() {
    // this.cancelUpgrade();
  }
};
</script>

<style lang="scss" scoped>
.col-form-label {
  padding-bottom: 5px;
  color: #5f5f5f;
}

.form-control-item,
.col-form-label {
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.triple {
  padding-bottom: 20px;
  padding-top: 10px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    border-bottom: 1px solid #f3f3f3;
  }
}
</style>
