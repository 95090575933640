/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import { getErrorServiceMsg } from '../../config';

const qs = require('qs');

const account = {
  state: () => ({
    notifications: [],
    actions: [],
    gateways: [],
    bankServices: [],
    issuersData: [],
    next: '',
    passwords: {
      curPassword: '',
      newPassword: '',
      reNewPassword: ''
    },
    bank_settings: {
      bank: '',
      account_name: '',
      iban: ''
    },
    bankAccountList: [],
    isHidden: false,
    countryCodes: [],
    formTwoData: [],
    formTwoRequired: [],
    responseNdLevel: {},
    twoNdLevel: [],
    level_2: {
      isLoading: false
    },
    showUpgrade: {
      mainMenu: true,
      secondLevel: false,
      thirdLevel: false
    },
    level_3: {
      documentTypes: [],
      formDatas: {},
      isLoading: false
    }
  }),
  mutations: {
    SET_ACTIONS(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.actions = [];
      }
      for (let el in payload.payload.data) {
        let object = payload.payload.data[el];
        state.actions.push(object);
      }
    },
    SET_GATEWAY_DATA(state, payload) {
      state.gateways = payload;
      let servicesArray = [];
      let issuersArrayMain = [];
      for (let k in payload) {
        if ('services' in payload[k]) {
          servicesArray.push(...payload[k].services);
          let issuersArray = [];
          for (let j in payload[k].services) {
            if ('issuers' in payload[k].services[j]) {
              issuersArray.push(payload[k].services[j].issuers);
            }
          }
          issuersArrayMain.push(...issuersArray);
        } else {
          servicesArray.push(payload[k]);
        }
      }
      state.bankServices = servicesArray;
      state.issuersData = [...issuersArrayMain[0]];
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = [];
      for (let key in payload.data) {
        let object = payload.data[key];
        Object.entries(object).forEach(([index, data]) => (state.notifications[index] = data.value));
      }
    },
    SET_BANK_ACCOUNT_LIST(state, payload) {
      state.bankAccountList = [];
      state.isHidden = false;
      for (let key in payload.data) {
        let object = payload.data[key];
        state.bankAccountList.push(object);
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_COUNTRY_CODES(state, payload) {
      state.countryCodes = [];
      for (let key in payload.countries) {
        let object = payload.countries[key];
        state.countryCodes.push(object);
      }
    },
    SET_FORM_2ND_LEVEL(state, payload) {
      state.formTwoData = [];
      state.formTwoRequired = [];
      state.responseNdLevel = payload;
    },
    showSecondLevel(state) {
      state.showUpgrade.thirdLevel = false;
      state.showUpgrade.secondLevel = true;
      state.showUpgrade.mainMenu = false;
    },
    showThirdLevel(state) {
      state.showUpgrade.secondLevel = false;
      state.showUpgrade.thirdLevel = true;
      state.showUpgrade.mainMenu = false;
    },
    cancelUpgrade(state) {
      state.showUpgrade.mainMenu = true;
      state.showUpgrade.secondLevel = false;
      state.showUpgrade.thirdLevel = false;
    },
    SET_DOCUMENTS(state, params) {
      state.level_3.documents = params;
    },
    SET_DOCUMENT_TYPE(state, params) {
      var documentTypes = [];
      Object.values(params.data.types[params.code]).forEach(function (item) {
        documentTypes.push({ name: item });
      });
      state.level_3.documentTypes = documentTypes;
    },
    SET_BANK_IMAGE(state, payload) {
      state.bankImage = payload;
    }
  },
  actions: {
    GET_ACTIONS({ state, commit }, params) {
      let nextPage = `${API.actions}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}`;
      }
      Services.get(nextPage)
        .then((res) => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_ACTIONS', data);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_NOTIFICATIONS({ commit }) {
      Services.get(API.notifications)
        .then((res) => {
          commit('SET_NOTIFICATIONS', res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DOCUMENTS({ commit }) {
      Services.get(API.documentsToCountry)
        .then((res) => {
          commit('SET_COUNTRY_CODES', res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DOCUMENT_TYPE({ commit }, code) {
      Services.get(`${API.documentTypes}${code}`)
        .then((res) => {
          commit('SET_DOCUMENT_TYPE', { code: code, data: res.data });
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_3ND_LEVEL({ commit }, params) {
      const data = {
        DataFields: params.content
      };
      Services.post(`${API.verifyDocument}${params.selectedCountry}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {
          commit('cancelUpgrade', false);
          Vue.$toast.success('İşleminiz Beklemede');
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_NOTIFICATION({ state }, params) {
      const data = {
        notification: params,
        value: state.notifications[params]
      };
      Services.post(API.notifications, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {})
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_PASSWORD({ state, dispatch }) {
      const data = {
        password_old: state.passwords.curPassword,
        password: state.passwords.newPassword,
        password_confirmation: state.passwords.reNewPassword
      };
      Services.post(API.updatePassword, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          Vue.$toast.success(res.data.message);
          dispatch('CANCEL_PASSWORD');
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CANCEL_PASSWORD({ state }) {
      state.passwords.curPassword = null;
      state.passwords.newPassword = null;
      state.passwords.reNewPassword = null;
    },
    async GET_BANK_ACCOUNT_LIST({ commit }) {
      const res = await Services.get(API.bankAccountList);
      commit('SET_BANK_ACCOUNT_LIST', res.data);
      return res;
    },
    GET_GATEWAY_LIST({ commit }, params) {
      if (params.id != undefined) {
        let data = {
          id: params.id
        };
        const headers = {
          'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
        };
        Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/getGatewayList', data, { headers })
          .then((res) => {
            commit('SET_GATEWAY_DATA', res.data.data);
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              Vue.$toast.error(err.response.data.message);
            }
          });
      }
    },

    async UPLOAD_DOCUMENT_WITHDRAW({ commit }, params) {
      let data = new FormData();
      data.append('file', params.file);
      data.append('uid', params.id);
      data.append('module', 'bankProof');

      const headers = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };

      await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/uploadDocument', data, { headers })
        .then(async (res) => {
          commit('SET_BANK_IMAGE_WITHDRAW', res.data.message.pathIs);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            console.log('error');
          }
        });
    },

    async ADD_BANK_ACCOUNT({ state, dispatch }, params) {
      let data = new FormData();
      data.append('file', params.file);
      data.append('uid', params.id);
      data.append('module', 'bankProof');

      const headers = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };
      dispatch('overlayLoader/showLoader', null, { root: true });
      try {
        const uploadDocumentResponse = await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/uploadDocument', data, { headers });
        if (uploadDocumentResponse && uploadDocumentResponse?.status === 200) {
          const imageUrl = uploadDocumentResponse.data.message.pathIs;
          const getBankIdRes = await Services.post(API.getBankId, qs.stringify({ code: state.bank_settings.bank.id }), { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
          let address = state.bank_settings.iban.trim().replace(/ +/g, '');
          const createWalletReqData = {
            provider: 'bank_transfer',
            currency: state.bank_settings.currency.code.toLowerCase(),
            address: address,
            bank: getBankIdRes.data,
            label: state.bank_settings.account_name,
            imageUrl
          };
          const createWalletRes = await Services.post(API.createWallet, qs.stringify(createWalletReqData), { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
          var payment_reference = 'PAY' + Math.floor(100000 + Math.random() * 900000) + '' + Math.floor(100000 + Math.random() * 900000);
          let sepaBankReqData = {
            type: 2,
            currency: state.bank_settings.currency.code.toLowerCase(),
            payment_mode: state.bank_settings.bank.id,
            fname: createWalletRes.data.data.user_name,
            lname: createWalletRes.data.data.user_surname,
            email: createWalletRes.data.data.user_email,
            user_id: createWalletRes.data.data.user_id,
            account_number: address,
            unique_reference: payment_reference,
            imageUrl
          };
          const headers = { 'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY };
          if (state.bank_settings.bank.id.toLowerCase() == 'sepa') {
            await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/sepaBankRequest', sepaBankReqData, { headers });
            Vue.$toast.success('Bank addedd Successfully');
          } else {
            const bitdenexResponse = await Services.post(process.env.VUE_APP_BDXAPI_URI + '/mi/verifyBankOnline', sepaBankReqData, { headers });
            window.open(bitdenexResponse.data.data.payment.url, '_self');
          }
        } else {
          throw {
            response: {
              data: {
                message: 'Image upload error'
              }
            }
          };
        }
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      } finally {
        dispatch('overlayLoader/hideLoader', null, { root: true });
      }
    },
    DELETE_BANK({ dispatch, commit }, bankCode) {
      Services.delete(API.withdraw + '/' + bankCode)
        .then((res) => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_BANK_ACCOUNT_LIST');
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_COUNTRY_CODES({ commit }) {
      Services.get(API.countryCodes)
        .then((res) => {
          commit('SET_COUNTRY_CODES', res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_FORM_2ND_LEVEL({ commit, dispatch }, params) {
      Services.get(`${API.formToCountry}${params}`)
        .then((res) => {
          commit('SET_FORM_2ND_LEVEL', res.data);
          dispatch('CREATE_FORM_2ND_LEVEL', res.data.DataFields);
          dispatch('SET_PREDEFINED_USER_INFOS_FOR_2ND_LEVEL_FORM', res.data.DataFields.properties);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CREATE_FORM_2ND_LEVEL({ state, dispatch }, params) {
      if (typeof params.required != 'undefined' && params.required.length > 0) state.formTwoRequired = [...state.formTwoRequired, ...params.required];
      Object.entries(params.properties).forEach(([key, value]) => {
        if (value.type != 'object') {
          state.formTwoData.push({ name: key, data: value });
        } else {
          dispatch('CREATE_FORM_2ND_LEVEL', value);
        }
      });
    },
    POST_2ND_LEVEL({ state, commit }, params) {
      state.level_2.isLoading = true;
      const data = {
        DataFields: params.content,
        ConsentForDataSources: []
      };
      Services.post(`${API.upgradeTwoNdLevel}${params.selectedCountry}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {
          // @INFO level upgrade logic before
          // this.dispatch('customer/GET_USER', { root: true });
          // commit('cancelUpgrade', false);
          // Vue.$toast.success(res.data.message);

          // @INFO level upgrade processes combined together
          this.dispatch('customer/GET_USER', { root: true });
          commit('showThirdLevel');
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        })
        .finally(() => {
          state.level_2.isLoading = false;
        });
    },
    POST_THIRD_LEVEL({ state, commit }) {
      let formData = new FormData();
      state.level_3.isLoading = true;
      Object.entries(state.level_3.formDatas).forEach(([key, value]) => {
        formData.append(key, value);
      });
      Services.post(API.verifyDocument, formData, {
        headers: { 'content-type': 'multipart/form-data' }
      })
        .then((res) => {
          this.dispatch('customer/GET_USER', { root: true });
          commit('cancelUpgrade', false);
          Vue.$toast.success(res.data.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        })
        .finally(() => {
          state.level_3.isLoading = false;
        });
    },
    SET_PREDEFINED_USER_INFOS_FOR_2ND_LEVEL_FORM({ state, rootState }, dataFieldsProps) {
      const { name, surname, address, phone } = rootState.customer.user;
      const phoneCleaned = phone ? phone.replace(/\D/g, '') : '';
      let fieldNames = {};
      // eslint-disable-next-line no-unused-vars
      Object.entries(dataFieldsProps).forEach(([key, value]) => {
        fieldNames = { ...fieldNames, ...value.properties };
      });
      if ('FirstGivenName' in fieldNames) state.twoNdLevel['FirstGivenName'] = name || '';
      if ('FirstSurName' in fieldNames) state.twoNdLevel['FirstSurName'] = surname || '';
      if ('Address' in fieldNames) state.twoNdLevel['Address'] = address || '';
      if ('MobileNumber' in fieldNames) state.twoNdLevel['MobileNumber'] = phoneCleaned;
      if ('Telephone' in fieldNames) state.twoNdLevel['Telephone'] = phoneCleaned;
      if ('Telephone2' in fieldNames) state.twoNdLevel['Telephone2'] = phoneCleaned;
    }
  },
  getters: {
    getGateways(state) {
      return state.gateways;
    }
  },
  namespaced: true
};

export default account;
