import logoDefault from '../assets/images/logo/MCM-NL.png';
import logoWhite from '../assets/images/logo/MCM-NL-white.png';
import logoMobile from '../assets/images/logo/logo-sm.202104212035.jpg';
import isDev from '@/config/isDev';

export default {
    data() {
        return {
            logoDefault,
            logoWhite,
            logoMobile,
        }
    },
    computed: {
        isDev() {
            return isDev;
        }
    }
}