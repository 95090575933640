/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import router from '@/router/index';
import i18n from '@/config/_i18n';
import { getSuccessServiceMsg, getErrorServiceMsg } from '@/config/serviceMessages';

const isProd = process.env.VUE_APP_ENV === 'prod';

const cdnBase = process.env.VUE_APP_OFINANS_CDN;
const infoset_api_key = process.env.VUE_APP_INFOSET_CHAT_BOOT_API_KEY;

const defaultPhoneCodeCountry = {
  title: 'Hollanda',
  desc: 'nl',
  img: `${cdnBase}/images/flags/NL.png`,
  code: '+31',
  phoneMask: ['##-#######-####']
};

const qs = require('qs');
var moment = require('moment');
const customer = {
  state: () => ({
    isLogin: (localStorage.getItem('isLogin') && localStorage.getItem('userToken')) || '',
    login: {
      phone: '',
      password: ''
    },
    otp: {
      command: Number,
      message: '',
      code: ''
    },
    forgotPassword: {
      phone: '',
      email: ''
    },
    resetPassword: {
      email: '',
      password: '',
      password_confirmation: '',
      token: ''
    },
    user: {},
    userNotifications: [],
    userlevels: {},
    unreadNotifications: 0,
    showOtpModal: false,
    isLoading: true,
    countries: [],
    cities: [],
    towns: [],
    taxbranches: [],
    options: [],
    phoneCode: defaultPhoneCodeCountry,
    register: {
      personel: {
        name: null,
        surname: null,
        email: null,
        password: null,
        passwordAgain: null,
        phone: null,
        phoneCode: defaultPhoneCodeCountry,
        acceptContract: null,
      },
      corporate: {
        name: null,
        surname: null,
        email: null,
        password: null,
        passwordAgain: null,
        phone: null,
        phoneCode: defaultPhoneCodeCountry,
        tax_number: null,
        tax_branch_id: null,
        company: null,
        country: null,
        city: null,
        taxOffice: null,
        acceptContract: null,
      }
    },
    showRegisterModal: false,
    showPolicyModal: false,
    showRegisterModalMessage: false,
    verifyEmail: null,
    next: '',
    kycApplicantId: {
      applicant_kyc_id: null,
      kycStatus: null,
      workflow_run_id: null
    },
    kycStart: {
      sdk_token: '',
      workflow_run_id: '',
      applicant_kyc_id_start: ''
    },
    kycUpdateStatus: null,
    googleAuthQr: null,
    referral_employee_code: null
  }),
  mutations: {
    SET_LOGIN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.removeItem('isLogin');
      state.otp.message = payload.message;
      if (payload.status === 'command') {
        state.showOtpModal = true;
        state.otp.command = payload.command;
      } else if (payload.status === 'ok') {
        localStorage.setItem('userToken', payload.access_token);
        router.push({ path: '/wallet' });
        state.login = { phone: '', password: '' };
        state.otp = { command: null, code: '' };
      }
    },
    SET_TOKEN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.setItem('refreshNextTime', Date.now() + 3600000);
      state.otp = {
        ...state.otp,
        message: payload.message,
        command: payload.command || 20,
        code: ''
      };
    },
    async SET_KYC_APPLICANTID(state, payload) {
      state.kycApplicantId.applicant_kyc_id = payload.applicant_kyc_id;
      state.kycApplicantId.kycStatus = payload.status;
      state.kycApplicantId.workflow_run_id = payload.workflow_run_id;
    },
    async START_KYC_RESPONSE(state, payload) {
      state.kycStart.applicant_kyc_id_start = payload.data.data.applicant_kyc_id;
      state.kycStart.workflow_run_id = payload.data.data.workflow_run_id;
      state.kycStart.sdk_token = payload.data.data.sdk_token;
    },
    SET_OTP_LOGIN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.setItem('isLogin', true);
      state.isLogin = true;
      state.login = { phone: '', password: '' };
      state.otp = { command: null, code: '' };
      state.showOtpModal = false;
      Services.put(API.updateLanguage, qs.stringify({ language: sessionStorage.getItem('lang') })).then(() => {});
      // router.push({ path: '/' });
      window.location = '/';
    },
    SET_USER_NOTIFICATIONS(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.userNotifications = [];
      }
      for (let key in payload.payload.data) {
        let object = payload.payload.data[key];
        var actionTime = moment(object.updated_at, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.userNotifications.push({ all_data: object, time_ago: timeAgo });
      }
    },
    BindNotificationsCount(state) {
      let result = state.userNotifications.filter(el => typeof el.all_data.read_at === 'undefined' || el.all_data.read_at === null);
      state.unreadNotifications = result.length;
    },
    SET_FORGOT_PASSWORD(state, payload) {
      Vue.$toast.success(payload.message);
    },
    SET_RESET_PASSWORD(state, payload) {
      if (payload.status === 'ok') {
        router.push({ path: '/login' });
      }
    },
    SET_LOGOUT(state) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('isLogin');
      localStorage.removeItem('maintenanceModalStatus');
      state.isLogin = false;
      window.location = '/login';
    },
    SET_USER(state, payload) {
      state.user = payload.data;
    },
    SET_COUNTRIES(state, payload) {
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.countries.push({
          title: object.name + ' ' + object.calling_code,
          desc: object.code,
          img: object.flag_png_url,
          code: object.calling_code,
          phoneMask: object.calling_mask_array[0] ? 
            object.calling_mask_array : 
            object.calling_mask ? 
            [object.calling_mask] : 
            ['(###) ### ## ##'],
          name: object.name,
          id: object.id
        });
      }
    },
    SET_CITIES(state, payload) {
      state.cities = [];
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.cities.push({ title: object.name, id: object.id });
      }
    },
    SET_TOWNS(state, payload) {
      state.towns = [];
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.towns.push({ title: object.name, id: object.id });
      }
    },
    SET_TAXBRANCHES(state, payload) {
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.taxbranches.push({ title: object.name, id: object.id });
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    closeModal(state, playload) {
      state.showOtpModal = playload;
      state.showRegisterModal = null;
      state.showRegisterModalMessage = null;
      state.showPolicyModal = null;
    },
    phoneUpdate() {},
    SET_LEVELS(state, payload) {
      state.userlevels = payload.data;
    },
    SET_VERIFY_EMAIL(state, payload) {
      state.verifyEmail = payload;
    },
    updatePolicyModal(state, playload) {
      state.show = playload;
    },
    async updateKycUpdate(state, playload) {
      state.kycUpdateStatus = playload;
    },
    SET_PHONE_CODE(state, payload) {
      if (payload && payload.constructor === Object && Object.keys(payload).length > 0) {
        state.phoneCode = payload;
        state.register.personel.phoneCode = payload;
        state.register.corporate.phoneCode = payload;
      }
    },
    SET_GOOGLE_AUTHENTICATOR(state, payload) {
      state.googleAuthQr = payload?.qr_image || null;
    },
    SET_DEFAULT_PHONE_CODE_COUNTRY(state, payload) {
      state.phoneCode = payload;
      state.register.personel.phoneCode = payload;
      state.register.corporate.phoneCode = payload;
    },
    SET_REFERRAL_CODES(state, payload) {
      state.referral_employee_code = payload;
    }
  },
  actions: {
    POST_LOGIN({ state, commit }, loginType) {
      if (loginType === 'corporate') API.login += '/corporate';

      Services.post(API.login, {
        phone: state.phoneCode.code + state.login.phone,
        password: state.login.password
      })
        .then(res => {
          commit('SET_LOGIN', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    // KYC_APPLICANTID({ state, dispatch, commit }) {

    //   if (state.kycApplicantId.kycStatus != 3 && state.kycApplicantId.kycStatus != 5
    //     && state.kycApplicantId.kycStatus != 6 && state.kycApplicantId.kycStatus != 404
    //     && state.kycApplicantId.workflow_run_id == null && state.kycApplicantId.kycStatus != null
    //     && state.kycApplicantId.workflow_run_id == undefined && state.kycApplicantId.kycStatus != undefined) {
    //     let getApplicantId = `${API.kycVerify}${state.user.id}`;
    //     Services.get(getApplicantId)
    //       .then(res => {
    //         let dataResponse = res.data.data;
    //         commit('SET_KYC_APPLICANTID', dataResponse);
    //         dispatch('START_KYC', { dataResponse: dataResponse });
    //       })
    //       .catch(err => {
    //         if (err?.response?.data?.message) {
    //           Vue.$toast.error(err.response.data.message);
    //         }
    //       });
    //   }

    // },
    async CREATE_KYC_WORKFLOWRUN_ID({ state, dispatch, commit }) {
      let getApplicantId = `${API.kycVerify}${state.user.id}`;
      await Services.get(getApplicantId)
        .then(async res => {
          console.log('Step 2');
          let dataResponse = res.data.data;
          await commit('SET_KYC_APPLICANTID', dataResponse);
          if (dataResponse.status == 404) {
            await dispatch('START_KYC', { dataResponse: dataResponse });
          } else {
            console.log('Step 3');
            console.log(state.kycApplicantId.workflow_run_id, 'Strp Unknown');
            if (state.kycApplicantId.workflow_run_id == null || state.kycApplicantId.workflow_run_id == '') {
              console.log('Step 4');

              var data = {
                first_name: state.user.name,
                last_name: state.user.surname,
                applicant_kyc_id_exist: 'Y',
                applicant_kyc_id: state.kycApplicantId.applicant_kyc_id,
                user_id: state.user.id
              };
              const headers = {
                'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
              };
              await Services.post(process.env.VUE_APP_BDXAPI_URI + API.startKyc, data, {
                headers
              })
                .then(async res => {
                  console.log(res, 'This is response from api anvitechie');
                  await commit('START_KYC_RESPONSE', res);
                  state.kycStart.applicant_kyc_id_start = state.kycApplicantId.applicant_kyc_id;
                  state.kycStart.workflow_run_id = res.data.data.workflow_run_id;
                  state.kycStart.sdk_token = res.data.data.sdk_token;
                  const dataUpdate = {
                    workflow_run_id: res.data.data.workflow_run_id,
                    sdk_token: res.data.data.sdk_token
                  };
                  console.log(dataUpdate, 'Data Updated in database');
                  await Services.put(API.kycVerify + state.user.id + '/update', dataUpdate);
                })
                .catch(err => {
                  if (err?.response?.data?.message) {
                    Vue.$toast.error(err.response.data.message);
                  }
                });
            } else {
              console.log('Step 5');
              state.kycStart.applicant_kyc_id_start = dataResponse.applicant_kyc_id;
              state.kycStart.workflow_run_id = dataResponse.workflow_run_id;
              state.kycStart.sdk_token = dataResponse.sdk_token;
            }
          }
        })
        .catch(err => {
          console.log('Step 6');

          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async KYC_UPDATESTATUS({ state }, params) {
      let dataUpdate = params;
      dataUpdate.id = state.user.id;
      let getApplicantStatus = `${API.kycVerify}update-status`;
      await Services.post(getApplicantStatus, dataUpdate)
        .then(async () => {
          if (parseInt(dataUpdate.status) === 1) {
            Vue.$toast.error('Please start KYC again');
          } else {
            Vue.$toast.success('KYC Under Process.');
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async UPDATE_EXCHANGE_CURRENCY() {
      let getExchangeCurrency = `${API.updateExchangeCurrency}`;
      Services.post(getExchangeCurrency).then(() => {});
    },
    async START_KYC({ state, commit }) {
      var data;
      data = {
        first_name: state.user.name,
        last_name: state.user.surname,
        applicant_kyc_id_exist: 'N',
        user_id: state.user.id
      };

      const headers = {
        'X-BITDENX-KEY': process.env.VUE_APP_BDXAPI_KEY
      };
      await Services.post(process.env.VUE_APP_BDXAPI_URI + API.startKyc, data, {
        headers
      })

        .then(async res => {
          // console.log('headerrrsss', headers);
          await commit('START_KYC_RESPONSE', res);
          state.kycStart.status = 1;
          const dataInsert = {
            id: state.user.id,
            applicant_kyc_id: res.data.data.applicant_kyc_id,
            workflow_run_id: res.data.data.workflow_run_id,
            sdk_token: res.data.data.sdk_token,
            status: 1
          };
          await Services.post(API.addKycDetails, dataInsert).then(() => {});
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async POST_SEND_CODE({ commit }) {
      try {
        const res = await Services.post(API.sendCode);
        commit('SET_TOKEN', res.data);
        commit('IS_LOADING', false);
        Vue.$toast.success(res.data.message);
        return res;
      } catch (error) {
        if (error?.response?.data?.message) {
          Vue.$toast.error(error.response.data.message);
        }
        throw error;
      }
    },
    POST_LOGIN_OTP({ state, commit }) {
      let code = parseInt(state.otp.code);
      Services.post(
        API.loginOtp,
        qs.stringify({
          command: state.otp.command,
          code: code
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then(res => {
          commit('SET_OTP_LOGIN', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_FORGOT_PASSWORD({ state, commit }) {
      Services.post(
        API.passwordRecovery,
        qs.stringify({
          phone: state.phoneCode.code + state.forgotPassword.phone,
          email: state.forgotPassword.email
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then(res => {
          commit('SET_FORGOT_PASSWORD', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_RESET_PASSWORD({ state, commit }, token) {
      state.resetPassword.token = token;
      Services.post(API.passwordReset, qs.stringify(state.resetPassword), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          Vue.$toast.success(res.data.message);
          commit('SET_RESET_PASSWORD', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_USER_NOTIFICATIONS({ state, commit }, params) {
      let nextPage = `${API.userNotifications}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}`;
      }
      Services.get(nextPage, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_USER_NOTIFICATIONS', data);
          commit('BindNotificationsCount');
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_READ_NOTIFICATIONS({ dispatch, commit }) {
      Services.post(API.readNotifications, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {
          dispatch('GET_USER_NOTIFICATIONS', { isNextPage: false });
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async GET_USER({ commit }) {
      const res = await Services.get(API.user, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
      commit('SET_USER', res.data);
      if (router.history.current.name != 'AccountSettings' && res.data.data.level < 30) {
        Vue.$toast.error(i18n.t('userLevelWarning'));
        router.push({ name: 'AccountSettings' });
      }

      commit('IS_LOADING', false);
      const { id: userId, name, surname, email, phone, created_at, phone_verified, locale, type, type_str, gender, gender_str, user_hash } = res.data.data;
      const chatVisitor = {
        id: userId,
        firstName: name,
        lastName: surname,
        email,
        phone,
        photoUrl: '',
        userHash: user_hash,
        createdAt: created_at,
        company: '',
        customFields: {
          phone_verified,
          locale: locale === 'nl' ? 'nl' : 'en',
          type,
          type_str,
          gender,
          gender_str
        }
      };
      if(isProd) {
        // eslint-disable-next-line no-undef
        InfosetChat('boot', {
          widget: {
            apiKey: infoset_api_key,
            tags: 'Miracle-NL-Trade'
          },
          visitor: chatVisitor
        });
      }
      return res;
    },
    async GET_LOGOUT({ commit, dispatch }) {
      try {
        await Services.get(API.logout, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' }
        });
      } finally {
        await dispatch('INIT_INFOSET_CHAT_BOOT');
        commit('SET_LOGOUT');
        commit('IS_LOADING', false);
      }
    },
    GET_COUNTRIES({ commit }) {
      return new Promise((resolve, reject) => {
        Services.get(API.countries)
          .then(res => {
            commit('SET_COUNTRIES', res.data);
            commit('IS_LOADING', false);
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
            if (err?.response?.data?.message) {
              Vue.$toast.error(err.response.data.message);
            }
          });
      });
    },
    CHANGE_COUNTRIES({ commit, dispatch, state }, params) {
      commit('IS_LOADING', false);
      state.cities = [];
      state.register.city = null;
      state.register.taxOffice = null;
      dispatch('GET_CITIES', params.desc);
    },
    GET_CITIES({ commit }, value) {
      Services.get(`${API.cities}${value}`)
        .then(res => {
          commit('SET_CITIES', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_TOWNS({ commit }, value) {
      Services.get(`${API.towns}${value}`)
        .then(res => {
          commit('SET_TOWNS', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CHANGE_CITIES({ commit, dispatch, state }, params) {
      commit('IS_LOADING', false);
      state.taxbranches = [];
      state.register.taxOffice = null;
      dispatch('GET_TAXBRANCHES', params.id);
    },
    GET_TAXBRANCHES({ commit }, value) {
      Services.get(`${API.taxbranches}${value}`)
        .then(res => {
          commit('SET_TAXBRANCHES', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_PERSONEL_REGISTER({ state, commit }) {
      Services.post(
        API.register,
        qs.stringify({
          name: state.register.personel.name,
          surname: state.register.personel.surname,
          email: state.register.personel.email,
          password: state.register.personel.password,
          phone: state.register.personel.phoneCode.code + state.register.personel.phone,
          referral_employee_code: state.referral_employee_code
        })
      )
        .then(res => {
          if (res.data.status == 'ok') {
            state.showRegisterModal = true;
            state.showRegisterModalMessage = res.data.message;
            commit('IS_LOADING', false);
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_CORPORATE_REGISTER({ state, commit }) {
      Services.post(
        `${API.register}/corporate`,
        qs.stringify({
          name: state.register.corporate.name,
          surname: state.register.corporate.surname,
          email: state.register.corporate.email,
          password: state.register.corporate.password,
          phone: state.register.corporate.phoneCode.code + state.register.corporate.phone,
          tax_number: state.register.corporate.tax_number,
          tax_branch_id: state.register.corporate.taxOffice,
          company: state.register.corporate.company,
          city: state.register.corporate.city.id,
          country: state.register.corporate.country.desc,
          referral_employee_code: state.referral_employee_code
        })
      )
        .then(res => {
          if (res.data.status == 'ok') {
            state.showRegisterModal = true;
            state.showRegisterModalMessage = res.data.message;
            commit('IS_LOADING', false);
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_LEVELS({ commit }) {
      Services.get(API.userlevels)
        .then(res => {
          commit('SET_LEVELS', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    VERIFY_EMAIL({ commit }, params) {
      Services.post(
        API.emailverify,
        qs.stringify({
          verify_code: params.verify_code,
          id: params.id
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then(res => {
          commit('SET_VERIFY_EMAIL', res.data.verified);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async INIT_INFOSET_CHAT_BOOT() {
      const res = await Services.get(API.infosetApi);
      if(isProd) {
        // eslint-disable-next-line no-undef
        InfosetChat('boot',{ 
          widget:{
            apiKey: infoset_api_key,
            tags: 'Miracle-NL-Trade'
          },
          visitor: {
            id: res.data.id,
            userHash: res.data.hashId
          }
        });
      }
    },
    /**
     * by ip address
     */
    async fetchCountryCodeByIp({ state, commit }) {
      try {
        let defaultCounrtyCode = 'TR';
        const { countryCode } = await (await fetch('http://ip-api.com/json')).json();
        const _country = `${countryCode ?? defaultCounrtyCode}`.toLowerCase();
        const findCountry = state.countries?.find(country => `${country.desc}`.toLowerCase() === _country);
        commit('SET_PHONE_CODE', findCountry);
        return findCountry;
      } catch (error) {
        return error;
      }
    },
    async FETCH_GOOGLE_AUTHENTICATOR({ commit }) {
      commit('IS_LOADING', true);
      try {
        const res = await Services.get(API.google_authenticator);
        commit('SET_GOOGLE_AUTHENTICATOR', res.data);
        commit('IS_LOADING', false);
        return res;
      } catch (error) {
        commit('IS_LOADING', false);
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async ENABLE_GOOGLE_AUTHENTICATOR(_, verify_code) {
      try {
        const res = await Services.post(API.google_authenticator, {
          verify_code
        });
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async DISABLE_GOOGLE_AUTHENTICATOR({ dispatch }, password) {
      try {
        const res = await Services.post(`${API.google_authenticator}/delete`, {
          password
        });
        dispatch('GET_LOGOUT');
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async verifyManuelKyc(_, formData) {
      try {
        const res = await Services.post(API.verifyDocument, formData, {
          headers: { 'content-type': 'multipart/form-data' }
        });
        Vue.$toast.success(getSuccessServiceMsg(res));
      } catch (error) {
        if (error?.response?.data?.message) {
          Vue.$toast.error(error.response.data.message);
        }
        throw error;
      }
    }
  },
  getters: {
    isAuthenticated: state => !!state.isLogin,
    userLevel: state => state.user.level,
    authStatus: state => state.user.level,
    showManuelKyc: (state) => {
      const parent_id = state.user?.parent_id || null;
      const checkType = state.user?.type ? `${state.user.type}` === '70' : false;
      const isCorporate = state.user?.type ? `${state.user.type}` === '20' : false;
      const isDealer = state.user?.type ? `${state.user.type}` === '30' : false;
      return (checkType && !parent_id) || isCorporate || isDealer;
    },
    
  },
  namespaced: true
};

export default customer;
