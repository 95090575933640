<template>
  <div class="overlay-loader" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('overlayLoader', ['isLoading'])
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    }
  },
  methods: {
    disableScroll() {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    },
    enableScroll() {
      document.body.style.overflow = ''; // Enable scrolling
    }
  },
  mounted() {
    if (this.isLoading) {
      this.disableScroll();
    }
  },
  beforeDestroy() {
    this.enableScroll();
  }
};
</script>

<style scoped>
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.spinner {
  border: 6px solid rgba(255, 255, 255, 0.5);
  border-left-color: #642583;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
