<template>
  <div class="content-wrapper">
    <Breadcrumb :title="$t('personalMenu-accountSettings')" />
    <div class="content-body customer">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-12">
          <div v-if="isLoading">{{$t('commons.data_loading')}}..</div>
          <section class="card" v-else>
            <div class="card-content" v-if="showUpgrade.mainMenu">
              <div class="card-body">
                <div class="col-12">
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-envelope"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-eMail') }}</h2>
                      <p>
                        <strong>{{ user.email }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-user"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-name') }} {{ $t('accountSettings-surname') }}</h2>
                      <p>
                        <strong>{{ user.fullname }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-call-end"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-phone') }}</h2>
                      <p>
                        <strong>{{ user.phone }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="row expand">
                    <div class="col-md-1 col-xl-1 col-12 d-none d-md-block">
                      <div class="crypto-circle rounded-circle">
                        <i class="icon-globe"></i>
                      </div>
                    </div>
                    <div class="col-md-11 col-xl-11 col-12">
                      <h2>{{ $t('accountSettings-language') }}</h2>
                      <p>
                        <strong>{{ getSelectedLangName }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="levelStatus">
                <div class="col-12">
                  <h5>{{ user.level_str }}</h5>
                  <hr />
                  <div class="alert alert-primary" role="alert">
                    <i class="la la-info"></i>
                    {{ $t('accountSettings-levelUpReqUnderReview') }}
                  </div>
                </div>
              </div>
              <div class="card-body" v-else>
                <div class="col-12">
                  <h5>{{ user.level_str }}</h5>
                  <hr />
                  <div class="row" v-if="userlevels['level' + authStatus / 10]">
                    <div class="col-md-9 col-xl-9 col-12">
                      <ul style="list-style: none" class="pl-2">
                        <li v-for="(item, index) in userlevels['level' + authStatus / 10].content" :key="index">
                          <i class="la la-asterisk"></i>{{ item }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-3 col-xl-3 col-12 d-md-block text-right">
                      <a
                        href="https://miraclecash.nl/"
                        target="_blank"
                        class="btn btn-outline-info round btn-block text-truncate"
                        :title="$t('accountSettings-cardMoreInfo')"
                      >
                        {{ $t('accountSettings-cardMoreInfo') }}
                      </a>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  v-if="authStatus / 10 == '1'"
                  @click="$store.commit('account/showSecondLevel')"
                  class="btn-gradient-primary btn-block text-center my-1"
                >
                  {{ $t('upgradeYourAccount') }}
                </a>
                <!-- <a
                  href="#"
                  v-else-if="authStatus / 10 == '2'"
                  @click="$store.commit('account/showThirdLevel')"
                  class="btn-gradient-primary btn-block text-center my-1"
                >
                  {{ $t('accountSettings-upgradeLvl2') }}
                </a> -->
                <div class="alert alert-primary" role="alert" v-else v-html="$t('level-complete')"></div>
              </div>
            </div>
            <div class="card-content">
              <template v-if="showUpgrade.secondLevel">
                <ManuelKyc v-if="showManuelKyc" />
                <SecondLevelUpgrade v-else />
              </template>
            </div>
          </section>
        </div>
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import '@/assets/css/pages/buy-ico.css';
import Sidebar from '@/components/personal/Sidebar.vue';
import Breadcrumb from '@/components/layout/Breadcrumb';
import SecondLevelUpgrade from '@/components/personal/SecondLevelUpgrade';
import { languages } from '@/config/langList';
import {SockIOService} from '@/config/ofinansWebSocket';
import ManuelKyc from '@/components/personal/ManuelKyc';

export default {
  name: 'AccountSettings',
  components: {
    Sidebar,
    Breadcrumb,
    SecondLevelUpgrade,
    ManuelKyc
  },
  data: () => ({
    languages
  }),
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      user: (state) => state.customer.user,
      isLoading: (state) => state.customer.isLoading,
      userlevels: (state) => state.customer.userlevels,
      showUpgrade: (state) => state.account.showUpgrade
    }),
    ...mapGetters('customer', ['authStatus', 'showManuelKyc']),
    levelStatus() {
      return this.user?.level_status ?? false;
    },
    getSelectedLangName() {
      return this.languages[this.lang]?.['title'] || 'English';
    },
  },
  methods: {
    ...mapActions('customer', ['GET_LEVELS', 'UPDATE_EXCHANGE_CURRENCY', 'GET_USER']),
    ...mapActions('account', ['CHANGE_LANG'])
  },
  created() {
    this.GET_LEVELS();
    this.UPDATE_EXCHANGE_CURRENCY();
    SockIOService.init();
    SockIOService.socket.on('kyc', async (/* data */) => {
      this.$store.commit('customer/IS_LOADING', true);
      await this.GET_USER();
      this.$store.commit('customer/IS_LOADING', false);
    });
  },
  destroyed() {
    SockIOService.disconnect(); 
  }
};
</script>
<style lang="scss">
.customer {
  h2 {
    font-size: 1rem;
  }

  .crypto-circle {
    width: 46px;
    height: 46px;
  }

  .crypto-circle i {
    font-size: 1.5rem !important;
    top: 13px;
    left: 13px;
  }

  b,
  strong {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 601px) {
  .expand {
    margin: 1.5rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .expand {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>
