<template>
    <div class="content-wrapper">
        <Breadcrumb :title="$t('spot-trade')" />

        <div class="top-page-container remove-margins">
            <TopBlock class="mx-2" />
            <div class="row main-block mx-2">
                <LeftBlock />
                <MiddleBlock />
                <RightBlock />
            </div>
            <BottomBlock />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb';
import TopBlock from '../components/SpotTrade/TopBlock';
import LeftBlock from '@/components/SpotTrade/LeftBlock';
import RightBlock from '@/components/SpotTrade/RightBlock';
import MiddleBlock from '@/components/SpotTrade/MiddleBlock';
import BottomBlock from '@/components/SpotTrade/BottomBlock';

export default {
    name: 'SpotTrade',
    components: { Breadcrumb, RightBlock, LeftBlock, MiddleBlock, BottomBlock, TopBlock },
    async beforeMount() {
        this.FETCH_ALL_PAIRS();
    },
    methods: {
        ...mapActions('spottrade', ['FETCH_ALL_PAIRS']),
    }
};
</script>

<style lang="scss" scoped>
$border: 1px solid rgb(227, 235, 243);
$secondary: #6b6f82;
$dark: #464855;

.remove-margins {
    margin: 0 -2.2rem;
}

.main-block {
    height: 970px;
    overflow-y: hidden;
    padding-bottom: 1rem;
}
</style>
