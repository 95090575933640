<template>
  <transition name="fade" v-if="showPromotionPopup === 'true' && hidedInRoutes">
    <div class="modal show text-center">
      <div class="modal__backdrop"></div>
      <div class="modal__dialog" v-click-outside="onClickOutside">
        <div class="modal__close" @click="closeModal()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
            <path fill="black"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
            </path>
          </svg>
        </div>
        <div class="modal__body">
          <div class="position-relative overflow-hidden"
            style="border-top-left-radius: 5px; border-top-right-radius: 5px">
            <img class="img-fluid" :src="getImgSource" alt="Promotion Image">
          </div>
        </div>
        <div class='modal__footer d-flex justify-content-center'>
          <div class="d-flex flex-column text-center">
            <!-- <a class="text-bold-700 cursor-pointer font-small-3 text-primary mb-1" @click="campaignDetails">
              {{ $t('click_for_campaign_details') }}
            </a> -->
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="dontShowAgain" />
              <span @click="onClickDontShowAgain" class="font-weight-bold">{{ $t('commons.dont_show_it_again') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/config/_i18n';
import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  name: 'PromotionPopup',
  computed: {
    ...mapState({
      showPromotionPopup: (state) => state.global.promotionPopup
    }),
    ...mapGetters('customer', ['isAuthenticated']),
    getImgSource() {
      let imageSource = require('@/assets/images/promotion/welcome-bonus-popup-en.jpg');
      if (i18n.locale === 'nl') imageSource = require('@/assets/images/promotion/welcome-bonus-popup-nl.jpg');
      return imageSource;
    },
    hidedInRoutes() {
      const disabledRoutes = ['ForgotPassword', 'EmailVerify', 'PasswordReset'];
      return !disabledRoutes.includes(this.$route.name);
    }
  },
  data() {
    return {
      dontShowAgain: false
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('global/SET_PROMOTION_POPUP', { show: false, dontShowAgain: this.dontShowAgain });
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    onClickOutside() {
      this.closeModal();
    },
    onClickDontShowAgain() {
      this.dontShowAgain = !this.dontShowAgain;
    },
    campaignDetails() {
      if(this.isAuthenticated) {
        this.closeModal();
        this.$router.push({ name: 'Campaigns' });
        return;
      }
      window.open('https://miraclecash.com/dont-miss-opportunity/', '_blank');
    }
  },
  created() {
    if (this.showPromotionPopup === 'true') {
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;

  &.show {
    display: block;
  }

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #ffffff;
    position: relative;
    max-width: 100%;
    width: auto !important;
    display: inline-block;
    margin: 50px auto;
    border-radius: 5px;
    z-index: 2;
  }

  &__close {
    padding: 7px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    z-index: 99;
    border: transparent;
    color: #000;
    cursor: pointer;
    outline: 0;
    position: absolute;
    top: -1%;
    right: -1%;
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  &__body {
    padding: 1px;
    border-radius: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }

  &__footer {
    padding: 10px 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
